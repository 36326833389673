import Card from '@shoreag/base/Card';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Spinner from '@shoreag/base/Spinner';
import { Query, useQuery } from 'react-apollo';
import { get, has } from 'lodash';
import Table from '@shoreag/base/Table';
import { AuthContext } from '@shoreag/auth';
import Box from '@shoreag/base/Box';
import generateRedirectPath from '@shoreag/helpers/generate-redirect-path';
import copy from 'clipboard-copy';
import formatDateTimeConcise from '@shoreag/helpers/format-date-time-concise';
import { capitalCase } from 'change-case';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import { TooltipBox } from '@shoreag/base/Tooltip';
import { Field, Form } from 'react-final-form';
import Dropdown from '@shoreag/base/Dropdown';
import noop from 'lodash/noop';
import moment from 'moment';
import { parse } from 'query-string';
import Route from '../Route';
import dashboardConstants from '../../utilities/dashboard-constants';
import setDashboardURL from '../../utilities/set-dashboard-url';
import {
  DATASET_STATUSES,
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
  TIME_FRAME,
  TIME_FRAME_MOMENT_RANGE,
  TIME_FRAME_OPTIONS,
} from '../../utilities/constants';
import config from '../../config';
import LinkWithVersion from '../LinkWithVersion';
import ResponsiveEllipsis from '../ResponsiveEllipsis';
import Tags from '../Tags';
import ValidationResultStatus from '../ValidationResultStatus';
import DotsIcon from '../DotsIcon';
import setActionDropdown from '../../utilities/set-action-dropdown';
import TagTooltipBox from '../TagTooltip/TagTooltipBox';
import DashboardPaginator from '../DashboardPaginator';
import isPermitted from '../../utilities/is-permitted';
import ButtonGroups from '../ButtonGroups';
import ToolbarButton from '../ToolbarButton';
import AssociateSchemaModal from '../AssociateSchemaModal';
import partnerQuery from '../../graphql/queries/partner-optimized.gql';
import allDatasetQuery from '../../graphql/queries/all-dataset.gql';
import DashboardFilterForm from '../DashboardFilterForm';
import DatasetDashboardAnalytics from '../DatasetDashboardAnalytics';
import PartnerInformation from '../PartnerOnBoardingSteps/PartnerInformation';
import valueMapperDefinitionQuery from '../../graphql/queries/all-value-mapper-definitions.gql';

const PartnerDetailsPage = ({ id, location, navigate }) => {
  const { user } = useContext(AuthContext);

  const [isAssociateSchemaModalOpen, setIsAssociateSchemaModalOpen] = useState(
    false
  );
  const [datasetId, setDatasetId] = useState(null);
  const [schemaIdWithVersion, setSchemaIdWithVersion] = useState(null);
  const [showAnalytics, setShowAnalytics] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [timeFrame, setTimeFrame] = useState(TIME_FRAME.MONTH);

  const hasWritePermission = isPermitted({
    desiredAction: PERMISSION_ACTIONS.W,
    desiredResource: PERMISSION_RESOURCES.DATASETS,
    ...user,
  });

  const defaultQuery = {
    field: 'partnerId',
    type: 'must',
    value: id,
  };

  const {
    filters,
    sortCriteria,
    pageCriteria,
  } = dashboardConstants.getCriterias({
    search: location.search,
    type: 'partner_dataset',
  });

  const query = dashboardConstants.getQuery({
    filters,
    page: pageCriteria,
    sort: sortCriteria,
    user,
  });

  const analyticsSearchBody = dashboardConstants.getQuery({
    filters,
    user,
  });

  const {
    data: valueMapperDefinitionsData,
    loading: valueMapperDefinitionsLoading,
  } = useQuery(valueMapperDefinitionQuery);
  const valueMapperDefinitions = get(
    valueMapperDefinitionsData,
    'allValueMapperDefinitions',
    []
  );

  const { data: partnerData, loading: partnerDataLoading } = useQuery(
    partnerQuery,
    {
      // fetchPolicy: 'network-only',
      variables: { id },
    }
  );

  const partner = get(partnerData, 'partner', {});
  const partnerName = get(
    partner,
    'partnerDetailsAndContacts.partnerInformation.partnerName',
    ''
  );

  const toggleAssociateSchemaModal = (datasetId, schemaIdVersion) => {
    setIsAssociateSchemaModalOpen(!isAssociateSchemaModalOpen);

    if (typeof datasetId === 'string') {
      setDatasetId(datasetId);
      setSchemaIdWithVersion(schemaIdVersion);
    }
  };

  return (
    <Route
      header={{
        icon: 'info',
        rightContainer: (
          <ButtonGroups>
            <Form
              initialValues={() => {
                const hasCreated = has(
                  parse(parse(location.search).filters),
                  'created'
                );
                return {
                  duration: (hasCreated && timeFrame) || TIME_FRAME.MONTH,
                };
              }}
              onSubmit={noop}
              render={() => (
                <Field
                  component={Dropdown}
                  inputWrapperSx={{
                    minWidth: '180px',
                  }}
                  label="Time Frame"
                  labelSx={{
                    fontSize: 2,
                    fontWeight: 'bold',
                    mx: 4,
                  }}
                  name="duration"
                  onChange={(value) => {
                    let filterValues = filters.values;
                    const setDashboardURLValues = {
                      filters,
                      filterValues,
                      page: pageCriteria,
                      pathname: location.pathname,
                      sort: sortCriteria,
                      // timeFrame: value,
                    };
                    if (value !== TIME_FRAME.CUSTOM) {
                      const fromDate = moment().toDate();
                      const toDate = moment()
                        .subtract(
                          TIME_FRAME_MOMENT_RANGE[value][0],
                          TIME_FRAME_MOMENT_RANGE[value][1]
                        )
                        .toDate();
                      filterValues = {
                        ...filterValues,
                        created: [toDate, fromDate],
                      };
                    } else {
                      delete filterValues.created;
                      delete setDashboardURLValues.timeFrame;
                    }
                    navigate(
                      setDashboardURL({
                        ...setDashboardURLValues,
                        filterValues,
                      }),
                      {
                        replace: true,
                      }
                    );
                    setTimeFrame(value);
                  }}
                  options={TIME_FRAME_OPTIONS}
                  wrapperSx={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                />
              )}
            />
            <ToolbarButton
              active={showAnalytics}
              icon="bar-chart"
              label="Analytics"
              onClick={() => {
                setShowAnalytics(!showAnalytics);
              }}
            />
            <ToolbarButton
              active={showFilters}
              icon={filters.filterApplied ? 'filter' : 'menu'}
              label="Filters"
              onClick={() => {
                setShowFilters(!showFilters);
              }}
            />
            {hasWritePermission && (
              <ToolbarButton
                icon="upload"
                label="Upload Dataset"
                link="/datasets/create"
              />
            )}
          </ButtonGroups>
        ),
        title: partnerName,
        type: 'partner',
      }}
      isPrivate
    >
      <DashboardFilterForm
        filters={filters}
        page={pageCriteria}
        showFilters={showFilters}
        sort={sortCriteria}
      />
      <DatasetDashboardAnalytics
        showAnalytics={showAnalytics}
        variables={{
          searchBody: JSON.stringify([...analyticsSearchBody, defaultQuery]),
        }}
      />
      <Box as="h2" sx={{ mb: 4, mt: 6 }}>
        Dataset
      </Box>
      <Query
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
        query={allDatasetQuery}
        variables={{
          searchBody: JSON.stringify([...query, defaultQuery]),
        }}
      >
        {({ data, loading }) => {
          const datasets = get(data, 'allDataset', []);

          return (
            <>
              <Card m={0} p={5}>
                <Table
                  header={[
                    { label: 'Name', value: 'info.name' },
                    { label: 'Description', value: 'info.description' },
                    'Partner Name',
                    'Tags',
                    { label: 'Creation Date', value: 'createdAt' },
                    { label: 'Dataset Status', value: 'datasetStatus' },
                    <Box textAlign="center">Validation</Box>,
                    '',
                  ]}
                  isLoading={loading}
                  onSortUpdate={(sortBy, orderBy) => {
                    return navigate(
                      setDashboardURL({
                        filters,
                        filterValues: filters.values,
                        page: pageCriteria,
                        pathname: location.pathname,
                        sort: {
                          sortBy,
                          sortDirection: orderBy,
                        },
                      }),
                      { replace: true }
                    );
                  }}
                  orderBy={sortCriteria.sortDirection}
                  rows={datasets.map((d) => {
                    const datasetStatusNotPending =
                      d.datasetStatus !== DATASET_STATUSES.PENDING;
                    const isBuildSchema = config.flags.buildSchema;

                    const {
                      dropdownItems,
                      dropdownActions,
                    } = setActionDropdown([
                      {
                        action: `/datasets/${d.id}`,
                        display: true,
                        label: ['view', 'View'],
                      },
                      {
                        action: `/datasets/${d.id}/edit`,
                        display: hasWritePermission,
                        label: ['edit', 'Edit'],
                      },
                      {
                        action: generateRedirectPath({
                          pathname: '/schemas/create',
                          queryParams: { datasetId: d.id },
                        }),
                        display:
                          hasWritePermission &&
                          datasetStatusNotPending &&
                          isBuildSchema,
                        label: ['plus', 'Build Schema'],
                      },
                      {
                        action: () =>
                          toggleAssociateSchemaModal(
                            d.id,
                            d.schema ? d.schema.id : null
                          ),
                        display: hasWritePermission && datasetStatusNotPending,
                        label: ['associate', 'Associate Schema'],
                      },
                      {
                        action: () => copy(d.id),
                        display: true,
                        label: ['copy', 'Copy ID'],
                      },
                    ]);

                    return [
                      <LinkWithVersion
                        link={`/datasets/${d.id}`}
                        name={d.name}
                      />,
                      <ResponsiveEllipsis text={d.description} />,
                      d.partner ? d.partner.partnerName : '-',
                      <Tags tags={d.tags} />,
                      formatDateTimeConcise(d.createdAt),
                      d.datasetStatus ? capitalCase(d.datasetStatus) : '-',
                      <ValidationResultStatus
                        status={get(d, 'validationResult.validationStatus')}
                      />,
                      <Box sx={{ float: 'right' }}>
                        <DropdownMenu
                          button={<DotsIcon />}
                          itemActions={dropdownActions}
                          items={dropdownItems}
                        />
                      </Box>,
                    ];
                  })}
                  sortBy={sortCriteria.sortBy}
                  tableSx={{
                    fontSize: 2,
                  }}
                />
                <TooltipBox id="tooltip" />
                <TagTooltipBox />
              </Card>
              <DashboardPaginator
                filters={{
                  page: pageCriteria.offset,
                }}
                goToNextPage={() =>
                  navigate(
                    setDashboardURL({
                      filters,
                      filterValues: filters.values,
                      page: {
                        offset: pageCriteria.offset + pageCriteria.pageSize,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  )
                }
                goToPreviousPage={() =>
                  navigate(
                    setDashboardURL({
                      filters,
                      filterValues: filters.values,
                      page: {
                        offset: pageCriteria.offset - pageCriteria.pageSize,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  )
                }
                isClientSidePagination
                pageTotal={datasets.length}
              />
            </>
          );
        }}
      </Query>

      {(partnerDataLoading || valueMapperDefinitionsLoading) && <Spinner />}
      {partner && (
        <>
          <Box as="h2" sx={{ mb: 4, mt: 6 }}>
            Information
          </Box>
          <PartnerInformation
            data={partner}
            valueMapperDefinitions={valueMapperDefinitions}
          />
          <AssociateSchemaModal
            datasetId={datasetId}
            isOpen={isAssociateSchemaModalOpen}
            location={location}
            schemaIdWithVersion={schemaIdWithVersion}
            searchBody={{ searchBody: JSON.stringify(query) }}
            toggle={toggleAssociateSchemaModal}
          />
        </>
      )}
    </Route>
  );
};

PartnerDetailsPage.propTypes = {
  id: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

PartnerDetailsPage.defaultProps = {
  id: null,
};

export default PartnerDetailsPage;
