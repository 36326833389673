import PropTypes from 'prop-types';
import React from 'react';
import ResponsiveEllipsis from '../ResponsiveEllipsis';
import TagTooltip from '../TagTooltip';
import mapTagsToString from '../../utilities/map-tags-to-string';
import mapTagsToStrings from '../../utilities/map-tags-to-strings';

const Tags = ({ tags }) => (
  <TagTooltip
    data-tip-disable={!Array.isArray(tags) || !tags.length}
    id="tags"
    tags={mapTagsToStrings(tags)}
  >
    <ResponsiveEllipsis text={mapTagsToString(tags)} />
  </TagTooltip>
);

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, value: PropTypes.string })
  ),
};

Tags.defaultProps = {
  tags: [],
};

export default Tags;
