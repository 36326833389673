import { Field } from 'react-final-form';
import Input from '@shoreag/base/Input';
import { composeValidations, required } from '@shoreag/validations';
import Dropdown from '@shoreag/base/Dropdown';
import Box from '@shoreag/base/Box';
import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { camelCase } from 'change-case';
import TextArea from '@shoreag/base/TextArea';
import { useQuery } from 'react-apollo';
import {
  API_KEY_TYPE_OPTIONS,
  API_KEY_TYPES,
} from '../../../../utilities/constants';
import { minimum, sshRsa } from '../../../../utilities/validations';
import partnerDexGeneratedKeyQuery from '../../../../graphql/queries/partner-dex-generated-key.gql';
import PipelineDropdown from '../../../PipelineDetailsFormSection/PipelineDropdown';

const SFTPForm = ({ formContext, formName }) => {
  let dexKey = {
    file: '',
    fileLoading: true,
    fileName: '',
  };
  const wrapperStyled = {
    flex: 'auto',
    maxWidth: ['100%', null, null, '50%', '33.33%'],
    mb: 6,
    mt: 0,
    px: 4,
    width: '100%',
  };
  const keyType = get(formContext.values, `${formName}.keyType`);
  let sftpFolder = get(formContext.values, `${formName}.sftpFolder`);
  if (!sftpFolder) {
    const partnerName = get(
      formContext.values,
      'partnerDetailsAndContacts.partnerInformation.partnerName'
    );
    formContext.form.change(`${formName}.sftpFolder`, camelCase(partnerName));
    sftpFolder = partnerName;
  }

  const isKeyTypeDex = keyType === API_KEY_TYPES.DEX_GENERATED_PRIVATE_KEY;
  const isKeyTypeClient = keyType === API_KEY_TYPES.CLIENT_PROVIDED_PUBLIC_KEY;
  if (sftpFolder && isKeyTypeDex) {
    const { data: dexGeneratedKeyData, loading } = useQuery(
      partnerDexGeneratedKeyQuery,
      {
        fetchPolicy: 'network-only',
        skip: !!dexKey.file,
        variables: { partnerName: sftpFolder },
      }
    );
    if (dexGeneratedKeyData) {
      const { partnerDexGeneratedKey } = dexGeneratedKeyData;
      if (loading) {
        dexKey = { ...dexKey, fileLoading: loading };
      }

      if (!loading && partnerDexGeneratedKey?.dexGeneratedKey) {
        dexKey = {
          ...dexKey,
          file: `data:text/plain;base64,${partnerDexGeneratedKey?.dexGeneratedKey}`,
          fileLoading: false,
          fileName: `${sftpFolder}_public_key.ppk`,
        };
      }
    }
  }
  const { file, fileLoading, fileName } = dexKey;

  const keyRotationPeriod = get(
    formContext.values,
    `${formName}.keyRotationPeriod`,
    0
  );
  const thresholdLimit = keyRotationPeriod > 1 ? keyRotationPeriod - 1 : 0;

  const maximumThreshold = (limit) => (value) => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(value) && (limit || limit === 0) && value > limit) {
      return `Should be less than rotation period`;
    }
  };

  return (
    <>
      <Field
        component={Input}
        data-cy={`${formName}.sftpFolder`}
        disabled
        label="SFTP Folder *"
        name={`${formName}.sftpFolder`}
        validate={required}
        wrapperSx={{
          ...wrapperStyled,
          maxWidth: ['100%', null, null, '50%', '66.66%'],
        }}
      />
      <Field
        component={Dropdown}
        data-cy={`${formName}.keyType`}
        label="key *"
        name={`${formName}.keyType`}
        options={API_KEY_TYPE_OPTIONS}
        validate={required}
        wrapperSx={{
          ...wrapperStyled,
          maxWidth: ['100%', null, null, '33.33%'],
        }}
      />
      <Field
        component={Input}
        label="Rotation period (in days)"
        min={0}
        name={`${formName}.keyRotationPeriod`}
        onInput={(event) => {
          const keyThreshold = get(
            formContext.values,
            `${formName}.keyThreshold`,
            0
          );
          if (
            Number(event.target.value) > 1 &&
            Number(keyThreshold) >= Number(event.target.value)
          ) {
            formContext.form.change(
              `${formName}.keyThreshold`,
              event.target.value - 1
            );
          }
          if (Number(event.target.value) <= 1) {
            formContext.form.change(`${formName}.keyThreshold`, 0);
          }
        }}
        type="number"
        validate={composeValidations(required, minimum(1))}
        wrapperSx={{
          ...wrapperStyled,
          maxWidth: ['100%', null, null, '33.33%'],
        }}
      />
      <Field
        component={Input}
        label="Threshold (in days)"
        max={thresholdLimit}
        min={0}
        name={`${formName}.keyThreshold`}
        subtext="Should be 0 or less than rotation period"
        type="number"
        validate={composeValidations(
          required,
          minimum(0),
          maximumThreshold(thresholdLimit)
        )}
        wrapperSx={{
          ...wrapperStyled,
          maxWidth: ['100%', null, null, '33.33%'],
        }}
      />
      {isKeyTypeClient && (
        <Field
          component={TextArea}
          data-cy={`${formName}.keyPath`}
          disabled={isKeyTypeDex}
          label="Enter Public Key *"
          name={`${formName}.keyPath`}
          type={isKeyTypeDex ? 'hidden' : ''}
          validate={composeValidations(required, sshRsa)}
          wrapperSx={{
            ...wrapperStyled,
            maxWidth: '100%',
          }}
        />
      )}
      {isKeyTypeDex && (
        <Box
          sx={{
            ...wrapperStyled,
            maxWidth: '100%',
          }}
        >
          <Box
            as={Link}
            download={fileName}
            href={file}
            sx={{
              display: 'inline-block',
              textAlign: 'center',
              width: '100%',
              ...(fileLoading
                ? {
                    cursor: 'not-allowed',
                    opacity: '0.5',
                    pointerEvents: 'none',
                  }
                : {}),
            }}
            variant="buttons.primary"
          >
            {fileLoading ? 'Loading...' : 'Download File'}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          '& > div': {
            '&:first-child': {
              maxWidth: ['100%', null, null, '66.66%'],
            },
          },
          display: 'flex',
          width: '100%',
        }}
      >
        <PipelineDropdown
          filedProps={{
            wrapperSx: { ...wrapperStyled },
          }}
          formContext={formContext}
          formName={formName}
          hideSpinner
          pipelineFieldName="pipelineCode"
          pipelineVersionFieldName="pipelineVersionId"
        />
      </Box>
    </>
  );
};

SFTPForm.propTypes = {
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }),
  formName: PropTypes.string.isRequired,
};

SFTPForm.defaultProps = {
  formContext: {},
};

export default SFTPForm;
