import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import {
  alpha,
  composeValidations,
  email,
  required,
} from '@shoreag/validations';
import Input from '@shoreag/base/Input';
import { FieldArray } from 'react-final-form-arrays';
import Card from '@shoreag/base/Card';
import Box from '@shoreag/base/Box';
import Dropdown from '@shoreag/base/Dropdown';
import { useQuery } from 'react-apollo';
// import ToggleSwitch from '@shoreag/base/ToggleSwitch';
import Checkbox from '@shoreag/base/Checkbox';
import get from 'lodash/get';
import RemoveButton from '../../RemoveButton';
import AddButton from '../../AddButton';
import allUserRoleDropdownQuery from '../../../graphql/queries/all-user-role-dropdown.gql';
import FlagHeading from '../../FlagHeading';
import { phoneNumber } from '../../../utilities/validations';

const PartnerContactsForm = ({
  children,
  formContext,
  formName,
  maxLimit,
  allowDefaultOne,
  ...sx
}) => {
  const { data } = useQuery(allUserRoleDropdownQuery, {
    fetchPolicy: 'network-only',
  });

  const allUserRole = get(data, 'allUserRole', []);

  if (allowDefaultOne && !get(formContext.values, formName)) {
    formContext.form.change(formName, [{}]);
  }
  const wrapperStyled = {
    flex: 'auto',
    maxWidth: ['100%', null, null, '50%', '33.33%'],
    mb: 6,
    mt: 0,
    px: 4,
    width: '100%',
  };
  return (
    <Box sx={sx}>
      <FieldArray name={formName}>
        {({ fields }) => {
          const totalFields = fields.length;
          return (
            <>
              <Box as="h2" sx={{ mb: 4, mt: 6 }}>
                Partner Contacts
              </Box>
              {totalFields > 0 ? (
                <>
                  {fields.map((name, index) => (
                    <Card
                      key={`${name}-${index}`}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 5,
                        pb: 0,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          mx: -4,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            mb: 5,
                            px: 4,
                            width: '100%',
                          }}
                        >
                          <FlagHeading color="accent" sx={{ mt: 0 }}>
                            Contact
                            <Box
                              bg="accent"
                              borderRadius={0}
                              color="white"
                              ml={3}
                              px={2}
                              py="2px"
                            >
                              {index + 1}
                            </Box>
                          </FlagHeading>
                          {!(allowDefaultOne && totalFields === 1) ? (
                            <RemoveButton onClick={() => fields.remove(index)}>
                              Remove
                            </RemoveButton>
                          ) : null}
                        </Box>
                        <Field
                          component={Input}
                          data-cy={`${name}.name`}
                          label="Name *"
                          name={`${name}.name`}
                          validate={composeValidations(required, alpha)}
                          wrapperSx={{
                            ...wrapperStyled,
                          }}
                        />
                        <Field
                          component={Input}
                          data-cy={`${name}.title`}
                          label="Title *"
                          name={`${name}.title`}
                          validate={required}
                          wrapperSx={{
                            ...wrapperStyled,
                          }}
                        />
                        <Field
                          component={Input}
                          data-cy={`${name}.email`}
                          label="Email *"
                          name={`${name}.email`}
                          validate={composeValidations(required, email)}
                          wrapperSx={{
                            ...wrapperStyled,
                          }}
                        />
                        <Field
                          component={Input}
                          data-cy={`${name}.phoneNumber`}
                          label="Phone Number *"
                          name={`${name}.phoneNumber`}
                          validate={composeValidations(required, phoneNumber)}
                          wrapperSx={{
                            ...wrapperStyled,
                          }}
                        />
                        <Field
                          component={Dropdown}
                          data-cy={`${name}.role`}
                          label="Role *"
                          name={`${name}.role`}
                          options={[
                            {
                              label: 'Administrator',
                              value:
                                'spr:bz:user-role::00000000-0000-0000-0000-000000000000',
                            },
                            ...allUserRole
                              .filter((role) => !role.disabled)
                              .map(({ id, name }) => ({
                                label: name,
                                value: id,
                              })),
                          ]}
                          validate={required}
                          wrapperSx={{
                            ...wrapperStyled,
                          }}
                        />
                        <Field
                          component={Checkbox}
                          data-cy={`${name}.dashboardAccess`}
                          label="Dashboard Access"
                          name={`${name}.dashboardAccess`}
                          type="checkbox"
                          wrapperSx={{
                            ...wrapperStyled,
                            alignItems: 'center',
                            display: 'flex',
                            pt: 6,
                          }}
                        />
                      </Box>
                    </Card>
                  ))}
                  {(!maxLimit || totalFields < maxLimit) && (
                    <AddButton
                      data-cy="addContact"
                      label="Add Contact"
                      onClick={() => fields.push({})}
                      wrapperSx={{ mt: 0 }}
                    />
                  )}
                </>
              ) : (
                <Card sx={{ display: 'flex', justifyContent: 'center' }}>
                  <AddButton
                    data-cy="addContact"
                    label="Add Contact"
                    onClick={() => fields.push({})}
                  />
                </Card>
              )}
            </>
          );
        }}
      </FieldArray>
    </Box>
  );
};

PartnerContactsForm.propTypes = {
  allowDefaultOne: PropTypes.bool,
  children: PropTypes.node,
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }),
  formName: PropTypes.string.isRequired,
  maxLimit: PropTypes.number,
};

PartnerContactsForm.defaultProps = {
  allowDefaultOne: false,
  children: null,
  formContext: {},
  maxLimit: null,
};

export default PartnerContactsForm;
