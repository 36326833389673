import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Query } from 'react-apollo';
import Box from '@shoreag/base/Box';
import stripTypename from '@shoreag/helpers/strip-typename';
import Spinner from '@shoreag/base/Spinner';
import SchemaDropdown from '../../../SchemaDropdown';
import allRecursiveSchemaDatumQuery from '../../../../graphql/queries/all-recursive-schema-datum.gql';
import RuleSection from '../../../PipelineInputComponent/PipelineValueMapperComponent/RuleSection';

const GroupAssociationWithValuemapper = ({ formContext, formName }) => {
  const sourceSchemaId = get(
    formContext.values,
    `${formName}.sourceSchemaParts.id`,
    ''
  );
  const sourceSchemaVersion = get(
    formContext.values,
    `${formName}.sourceSchemaParts.version`,
    ''
  );
  const isSourceSchema = get(
    formContext.values,
    `${formName}.sourceSchema`,
    ''
  );
  const sourceSchema =
    sourceSchemaId &&
    sourceSchemaVersion &&
    [sourceSchemaId, sourceSchemaVersion].join(':');

  if (!isSourceSchema && sourceSchemaId && sourceSchemaVersion) {
    formContext.form.change(`${formName}.sourceSchema`, sourceSchema);
  }

  const getNestedFieldName = (datumList, schemaId, fieldName) => {
    const parentSchemaDatum = datumList.find(
      (d) => d.nestedSchemaWithVersion?.id === schemaId
    );
    if (parentSchemaDatum?.definitionType === 'object') {
      return getNestedFieldName(
        datumList,
        parentSchemaDatum.schemaVersionId,
        `${parentSchemaDatum.fieldName}.${fieldName}`
      );
    }
    return parentSchemaDatum
      ? `${parentSchemaDatum.fieldName}.${fieldName}`
      : fieldName;
  };

  const updateDatumFieldName = (datumList) => {
    return datumList.map((datum) =>
      datum.definitionType !== 'object'
        ? {
            ...datum,
            fieldName: getNestedFieldName(
              datumList,
              datum.schemaVersionId,
              datum.fieldName
            ),
          }
        : datum
    );
  };

  const datumSortFunction = (datums) =>
    datums
      .sort((a, b) =>
        a.fieldName.toLowerCase() > b.fieldName.toLowerCase() ? 1 : -1
      )
      .sort((a, b) => {
        return (
          (a.fieldName.toString().match(/\./g) || []).length -
          (b.fieldName.toString().match(/\./g) || []).length
        );
      });

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <SchemaDropdown
          filedProps={{
            wrapperSx: {
              flex: 'auto',
              mr: 3,
              mt: 0,
            },
          }}
          formContext={formContext}
          formName={`${formName}.sourceSchemaParts`}
          hideSpinner
          idFieldName="id"
          isRequired
          versionFieldName="version"
        />
      </Box>
      {sourceSchema && (
        <>
          <Box sx={{ width: '100%' }}>
            <Query
              query={allRecursiveSchemaDatumQuery}
              variables={{ id: sourceSchema }}
            >
              {({ data, loading }) => {
                if (loading) {
                  return <Spinner py="md" />;
                }
                if (data) {
                  const datums = datumSortFunction(
                    updateDatumFieldName(
                      stripTypename(get(data, 'allRecursiveSchemaDatum', []))
                    )
                  );
                  return (
                    <>
                      <RuleSection
                        disableAction
                        formContext={formContext}
                        name={`${formName}.groupCondition`}
                        ruleBuilderTitle="Group Builder"
                        ruleVariablesTitle="Group Variables"
                        schemaColumns={datums}
                        singleRuleOnly
                      />
                      <Box
                        sx={{
                          bg: 'grays.1',
                          borderColor: 'grays.2',
                          borderRadius: 2,
                          borderStyle: 'solid',
                          borderWidth: '1px',
                          mt: 6,
                          pb: 3,
                          px: 3,
                        }}
                      >
                        <RuleSection
                          formContext={formContext}
                          name={`${formName}.ruleSet`}
                          schemaColumns={datums}
                        />
                      </Box>
                    </>
                  );
                }
                return null;
              }}
            </Query>
          </Box>
        </>
      )}
    </>
  );
};

GroupAssociationWithValuemapper.propTypes = {
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }),
  formName: PropTypes.string.isRequired,
};

GroupAssociationWithValuemapper.defaultProps = {
  formContext: {},
};

export default GroupAssociationWithValuemapper;
