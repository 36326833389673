import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { required } from '@shoreag/validations';
import { FieldArray } from 'react-final-form-arrays';
import Card from '@shoreag/base/Card';
import Box from '@shoreag/base/Box';
import Dropdown from '@shoreag/base/Dropdown';
import get from 'lodash/get';
import RemoveButton from '../../RemoveButton';
import AddButton from '../../AddButton';
import FlagHeading from '../../FlagHeading';
import {
  API_MODES,
  DELIVERY_TYPE,
  DELIVERY_TYPE_OPTIONS,
  END_OF_NAME_REGEX,
} from '../../../utilities/constants';
import SFTPForm from './SFTPForm';
import APIForm from './APIForm';
import AuthenticationDetailsForm from '../AuthenticationDetailsForm';

const DeliveryDetailsForm = ({
  children,
  formContext,
  formName,
  maxLimit,
  allowDefaultOne,
  ...sx
}) => {
  if (allowDefaultOne && !get(formContext.values, formName)) {
    formContext.form.change(formName, [{}]);
  }
  const deliveryDetailsValues = get(formContext.values, formName) || [];
  const isSftpExists =
    deliveryDetailsValues.filter((i) => i.deliveryType === DELIVERY_TYPE.SFTP)
      .length > 0;
  const isCallBackExists =
    deliveryDetailsValues.filter(
      (i) =>
        i.deliveryType === DELIVERY_TYPE.API && i.apiMode === API_MODES.CALLBACK
    ).length > 0;
  const wrapperStyled = {
    flex: 'auto',
    maxWidth: ['100%', null, null, '50%', '33.33%'],
    mb: 6,
    mt: 0,
    px: 4,
    width: '100%',
  };
  const authenticationDetailsLocation = formName.replace(
    END_OF_NAME_REGEX,
    '.authenticationDetails'
  );
  return (
    <>
      <Box sx={sx}>
        <FieldArray name={formName}>
          {({ fields }) => {
            const totalFields = fields.length;
            return (
              <>
                <Box as="h2" sx={{ mb: 4, mt: 6 }}>
                  Delivery Details
                </Box>
                {totalFields > 0 ? (
                  <>
                    {fields.map((name, index) => {
                      const deliveryType = get(
                        formContext.values,
                        `${name}.deliveryType`
                      );
                      let deliveryTypeOptions = DELIVERY_TYPE_OPTIONS;
                      if (deliveryType) {
                        deliveryTypeOptions = DELIVERY_TYPE_OPTIONS.filter(
                          (i) => i.value === deliveryType
                        );
                      } else {
                        deliveryTypeOptions =
                          deliveryType !== DELIVERY_TYPE.SFTP && isSftpExists
                            ? DELIVERY_TYPE_OPTIONS.filter(
                                (i) => i.value !== DELIVERY_TYPE.SFTP
                              )
                            : DELIVERY_TYPE_OPTIONS;
                      }
                      return (
                        <Card
                          key={`${name}-${index}`}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mb: 5,
                            pb: 0,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              mx: -4,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                mb: 5,
                                px: 4,
                                width: '100%',
                              }}
                            >
                              <FlagHeading color="accent" sx={{ mt: 0 }}>
                                Delivery
                                <Box
                                  bg="accent"
                                  borderRadius={0}
                                  color="white"
                                  ml={3}
                                  px={2}
                                  py="2px"
                                >
                                  {index + 1}
                                </Box>
                              </FlagHeading>
                              {!(allowDefaultOne && totalFields === 1) ? (
                                <RemoveButton
                                  onClick={() => fields.remove(index)}
                                >
                                  Remove
                                </RemoveButton>
                              ) : null}
                            </Box>
                            <Field
                              component={Dropdown}
                              data-cy={`${name}.deliveryType`}
                              label="Delivery Type *"
                              name={`${name}.deliveryType`}
                              options={deliveryTypeOptions}
                              validate={required}
                              wrapperSx={{
                                ...wrapperStyled,
                                maxWidth: ['100%', null, null, '50%', '33.33%'],
                              }}
                            />
                            {deliveryType === 'sftp' && (
                              <SFTPForm
                                formContext={formContext}
                                formName={name}
                              />
                            )}
                            {deliveryType === 'api' && (
                              <APIForm
                                formContext={formContext}
                                formName={name}
                              />
                            )}
                          </Box>
                        </Card>
                      );
                    })}
                    {(!maxLimit || fields.length < maxLimit) && (
                      <AddButton
                        data-cy="addGroup"
                        label="Add Group"
                        onClick={() => fields.push({})}
                        wrapperSx={{ mt: 0 }}
                      />
                    )}
                  </>
                ) : (
                  <Card sx={{ display: 'flex', justifyContent: 'center' }}>
                    <AddButton
                      data-cy="addGroup"
                      label="Add Group"
                      onClick={() => fields.push({})}
                    />
                  </Card>
                )}
              </>
            );
          }}
        </FieldArray>
      </Box>
      {isCallBackExists && (
        <AuthenticationDetailsForm
          allowDefaultOne
          formContext={formContext}
          formName={authenticationDetailsLocation}
        />
      )}
    </>
  );
};

DeliveryDetailsForm.propTypes = {
  allowDefaultOne: PropTypes.bool,
  children: PropTypes.node,
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }),
  formName: PropTypes.string.isRequired,
  maxLimit: PropTypes.number,
};

DeliveryDetailsForm.defaultProps = {
  allowDefaultOne: false,
  children: null,
  formContext: {},
  maxLimit: null,
};

export default DeliveryDetailsForm;
