import Box from '@shoreag/base/Box';
import Input from '@shoreag/base/Input';
import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { required } from '@shoreag/validations';
import Card from '@shoreag/base/Card';
import Dropdown from '@shoreag/base/Dropdown';
import PropTypes from 'prop-types';
import { sentenceCase, capitalCase } from 'change-case';
import TextArea from '@shoreag/base/TextArea';
import get from 'lodash/get';
import RemoveButton from '../../RemoveButton';
import AddButton from '../../AddButton';
import FlagHeading from '../../FlagHeading';
import {
  ESCALATION_LEVEL,
  ESCALATION_LEVEL_COLOR_MAP,
} from '../utilities/constants';
import AllUsersDropdown from '../../AllUsersDropdown';
import PillsGroup from '../../PillsGroup';

const EscalationLevelForm = ({
  allowDefaultOne,
  formContext,
  formName,
  maxLimit,
  sx,
}) => {
  const colStyled = {
    flex: 'auto',
    maxWidth: ['100%', null, null, '50%'],
    mt: 0,
    p: 4,
    width: '100%',
  };

  const setDays = () => {
    const days = [];
    for (let i = 1; i <= 30; i += 1) {
      days.push({
        label: `${i}`,
        value: `${i}`,
      });
    }
    return days;
  };
  const { values, invalid } = formContext;

  const escalationLevel =
    (values &&
      values[formName] &&
      values[formName].map((i) => i.severity).filter((i) => i)) ||
    [];

  const setLabelCase = (label) => {
    return sentenceCase(capitalCase(label));
  };

  if (allowDefaultOne && !get(formContext.values, formName)) {
    formContext.form.change(formName, [{}]);
  }

  return (
    <Box sx={sx}>
      <FieldArray name={formName}>
        {({ fields }) => (
          <>
            <Box as="h2" sx={{ mb: 4, mt: 6 }}>
              Escalation Levels
            </Box>
            {fields.map((name, index) => {
              const severity = escalationLevel[index];
              const labelColor =
                ESCALATION_LEVEL_COLOR_MAP[severity] || 'gray5';
              return (
                <Card
                  key={name}
                  sx={{ display: 'flex', flexDirection: 'column', mb: 5 }}
                >
                  <Box sx={{ display: 'flex', mb: 5 }}>
                    <FlagHeading color={labelColor} sx={{ mt: 0 }}>
                      Escalation
                      <Box
                        bg={labelColor}
                        borderRadius={0}
                        color="white"
                        ml={3}
                        px={2}
                        py="2px"
                      >
                        Level {index + 1}
                      </Box>
                      {severity && (
                        <Box
                          color="textSubtle"
                          display="inline-block"
                          fontSize={2}
                          ml={3}
                        >
                          {setLabelCase(severity)}
                        </Box>
                      )}
                    </FlagHeading>
                    <RemoveButton onClick={() => fields.remove(index)}>
                      Remove
                    </RemoveButton>
                  </Box>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', m: -4 }}>
                    <Box sx={colStyled}>
                      <Field
                        component={Input}
                        data-cy={`${name}.label`}
                        label="label *"
                        name={`${name}.label`}
                        validate={required}
                      />
                      <Field
                        component={Dropdown}
                        data-cy={`${name}.severity`}
                        label="Severity *"
                        name={`${name}.severity`}
                        options={ESCALATION_LEVEL.map((level, optionIndex) => {
                          const prevSeverity = escalationLevel[index - 1];
                          const prevIndex = prevSeverity
                            ? ESCALATION_LEVEL.indexOf(prevSeverity)
                            : -1;
                          return optionIndex >= prevIndex
                            ? {
                                label: sentenceCase(capitalCase(level)),
                                value: level,
                              }
                            : null;
                        }).filter((i) => i)}
                        validate={required}
                      />
                      <Field
                        component={Dropdown}
                        data-cy={`${name}.scheduleInterval`}
                        label="Days after timeline or schedule *"
                        name={`${name}.scheduleInterval`}
                        options={setDays()}
                        validate={required}
                      />
                    </Box>
                    <Box sx={colStyled}>
                      <AllUsersDropdown
                        data-cy={`${name}.email`}
                        isMulti
                        isRequired
                        label="Notify *"
                        name={`${name}.email`}
                      />
                      <PillsGroup
                        data={get(formContext.values, `${name}.email`, []).map(
                          (email) => ({
                            label: email,
                          })
                        )}
                        onRemove={(parameter) =>
                          formContext.form.change(
                            `${name}.email`,
                            get(formContext.values, `${name}.email`, []).filter(
                              (p) => p !== parameter.label
                            )
                          )
                        }
                        pillSx={{
                          bg: 'accent',
                        }}
                        wrapperSx={{
                          pt: 4,
                        }}
                      />
                      <Field
                        component={TextArea}
                        data-cy={`${name}.message`}
                        label="Notification Message *"
                        name={`${name}.message`}
                        rows={6}
                        validate={required}
                      />
                    </Box>
                  </Box>
                </Card>
              );
            })}
            {(fields.length < maxLimit || !maxLimit) && (
              <>
                {fields.length > 0 ? (
                  <AddButton
                    data-cy="addLevel"
                    label="Add Level"
                    onClick={() => {
                      const defaultValues =
                        fields.length > 0 && fields.value
                          ? fields?.value[fields.length - 1]
                          : {};
                      fields.push({
                        email: defaultValues?.email || [],
                      });
                    }}
                    sx={{
                      opacity: invalid ? '.5' : '',
                      pointerEvents: invalid ? 'none' : '',
                    }}
                  />
                ) : (
                  <Card sx={{ display: 'flex', justifyContent: 'center' }}>
                    <AddButton
                      data-cy="addLevel"
                      label="Add Escalation Level"
                      onClick={() => fields.push({})}
                      wrapperSx={{ mt: 4 }}
                    />
                  </Card>
                )}
              </>
            )}
          </>
        )}
      </FieldArray>
    </Box>
  );
};

EscalationLevelForm.propTypes = {
  allowDefaultOne: PropTypes.bool,
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    invalid: PropTypes.bool,
    values: PropTypes.shape({}),
  }),
  // formContext: PropTypes.shape({
  //   values: PropTypes.shape({
  //     escalationLevel: PropTypes.arrayOf(
  //       PropTypes.shape({
  //         severity: PropTypes.string,
  //       })
  //     ),
  //   }),
  // }),
  formName: PropTypes.string.isRequired,
  maxLimit: PropTypes.number,
  sx: PropTypes.shape({}),
};

EscalationLevelForm.defaultProps = {
  allowDefaultOne: false,
  formContext: {},
  maxLimit: null,
  sx: {},
};

export default EscalationLevelForm;
