import PropTypes from 'prop-types';
import React from 'react';
import { camelCase } from 'change-case';
import Box from '@shoreag/base/Box';
import { FieldArray } from 'react-final-form-arrays';
import get from 'lodash/get';
import Input from '@shoreag/base/Input';
import { required } from '@shoreag/validations';
import { Field } from 'react-final-form';
import AddButton from '../AddButton';
import RemoveButton from '../RemoveButton';

const KeyValuePairForm = ({
  keyLabel,
  valueLabel,
  addButtonLabel,
  formName,
  formContext,
  maxLimit,
  allowDefaultOne,
  divider,
}) => {
  if (allowDefaultOne && !get(formContext.values, formName)) {
    formContext.form.change(formName, [{}]);
  }
  const wrapperStyled = {
    flex: 'auto',
    maxWidth: ['100%', null, null, '33.33%'],
    mb: 6,
    mt: 0,
    px: 4,
    width: '100%',
  };
  return (
    <Box sx={{ mb: 5, width: '100%' }}>
      <FieldArray name={formName}>
        {({ fields }) => {
          const totalFields = fields.length;
          return (
            <>
              {fields.map((name, index) => (
                <Box
                  key={`${name}-${index}`}
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Field
                    component={Input}
                    data-cy={`${name}.parameter`}
                    label={keyLabel}
                    name={`${name}.parameter`}
                    validate={required}
                    wrapperSx={{
                      ...wrapperStyled,
                    }}
                  />
                  <Field
                    component={Input}
                    data-cy={`${name}.value`}
                    label={valueLabel}
                    name={`${name}.value`}
                    validate={required}
                    wrapperSx={{
                      ...wrapperStyled,
                    }}
                  />
                  {!(allowDefaultOne && totalFields === 1) ? (
                    <RemoveButton
                      onClick={() => fields.remove(index)}
                      sx={{
                        alignSelf: 'start',
                        flex: 'none',
                        ml: 0,
                        mt: 6,
                        px: 4,
                      }}
                    >
                      Remove
                    </RemoveButton>
                  ) : null}
                </Box>
              ))}
              {(!maxLimit || totalFields < maxLimit) && (
                <AddButton
                  {...(addButtonLabel
                    ? {
                        'data-cy': camelCase(addButtonLabel),
                        label: addButtonLabel,
                      }
                    : {})}
                  onClick={() => fields.push({})}
                  wrapperSx={{ justifyContent: 'flex-start', mt: 0, px: 4 }}
                />
              )}
            </>
          );
        }}
      </FieldArray>
      {divider && (
        <Box
          sx={{
            borderBottom: '1px solid',
            borderColor: 'grays.2',
            mb: 4,
            mt: 5,
            mx: 4,
          }}
        />
      )}
    </Box>
  );
};

KeyValuePairForm.propTypes = {
  addButtonLabel: PropTypes.string,
  allowDefaultOne: PropTypes.bool,
  divider: PropTypes.bool,
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }),
  formName: PropTypes.string.isRequired,
  keyLabel: PropTypes.string,
  maxLimit: PropTypes.number,
  valueLabel: PropTypes.string,
};

KeyValuePairForm.defaultProps = {
  addButtonLabel: '',
  allowDefaultOne: false,
  divider: true,
  formContext: {},
  keyLabel: 'Parameter',
  maxLimit: null,
  valueLabel: 'Value',
};

export default KeyValuePairForm;
