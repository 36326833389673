import Dropdown from '@shoreag/base/Dropdown';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';
import { Field } from 'react-final-form';
import { required } from '@shoreag/validations';
import get from 'lodash/get';
import SchemaVersionsDropdown from '../SchemaVersionsDropdown';
import allSchemaOptimizedQuery from '../../graphql/queries/all-schema-optimized.gql';
import stringArrayJoiner from '../../utilities/string-array-joiner';

const SchemaDropdown = ({
  formContext,
  isRequired,
  schemaId,
  disabled,
  formName,
  filedProps,
  hideSpinner,
  idFieldName,
  versionFieldName,
}) => {
  const [externalAsyncOptions, setExternalAsyncOptions] = useState([]);
  const schemaIdFieldName = stringArrayJoiner([formName, idFieldName]);
  const versionIdFieldName = stringArrayJoiner([formName, versionFieldName]);

  const schemaIdValue = get(formContext.values, schemaIdFieldName, schemaId);
  return (
    <>
      <ApolloConsumer>
        {(client) => (
          <Field
            component={Dropdown}
            data-cy={schemaIdFieldName}
            disabled={disabled}
            externalAsyncOptions={externalAsyncOptions}
            label={`Schema Name ${isRequired ? '*' : ''}`}
            loadOptions={async (query) => {
              const variables = { name: query, optimized: true };

              if (schemaId && query === '') {
                variables.ids = [schemaId];
              }

              const {
                data: { allSchema },
              } = await client.query({
                query: allSchemaOptimizedQuery,
                variables,
              });

              return allSchema.map(({ id, name, description }) => ({
                label: name,
                subtext: description,
                value: id,
              }));
            }}
            name={schemaIdFieldName}
            onChange={() =>
              formContext.form.change(versionIdFieldName, undefined)
            }
            setExternalAsyncOptions={setExternalAsyncOptions}
            validate={isRequired ? required : null}
            {...filedProps}
          />
        )}
      </ApolloConsumer>
      <SchemaVersionsDropdown
        disabled={disabled}
        filedProps={filedProps}
        formName={versionIdFieldName}
        hideSpinner={hideSpinner}
        isRequired={isRequired}
        schemaId={schemaIdValue}
      />
    </>
  );
};

SchemaDropdown.propTypes = {
  disabled: PropTypes.bool,
  filedProps: PropTypes.shape({}),
  formContext: PropTypes.shape({
    form: PropTypes.shape({
      change: PropTypes.func,
    }),
    values: PropTypes.shape({
      schemaId: PropTypes.string,
    }),
  }).isRequired,
  formName: PropTypes.string,
  hideSpinner: PropTypes.bool,
  idFieldName: PropTypes.string,
  isRequired: PropTypes.bool.isRequired,
  schemaId: PropTypes.string,
  versionFieldName: PropTypes.string,
};

SchemaDropdown.defaultProps = {
  disabled: false,
  filedProps: {},
  formName: '',
  hideSpinner: false,
  idFieldName: 'schemaId',
  schemaId: null,
  versionFieldName: 'versionId',
};

export default SchemaDropdown;
