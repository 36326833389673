import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { composeValidations, email, required } from '@shoreag/validations';
import Input from '@shoreag/base/Input';
import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import Dropdown from '@shoreag/base/Dropdown';
import SingleDatePicker from '../../SingleDatePicker';
import { isValidIpAddress } from '../../../utilities/validations';
import InputMaskedIp from '../../InputMaskedIp';

const PartnerDetailsForm = ({ formName, partnerId }) => {
  const wrapperStyled = {
    flex: 'auto',
    maxWidth: ['100%', null, null, '50%', '33.33%'],
    mt: 0,
    p: 4,
    width: '100%',
  };
  return (
    <>
      <Box as="h2" sx={{ mb: 4, mt: 6 }}>
        Partner Details
      </Box>
      <Card>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            m: -4,
          }}
        >
          <Field
            component={Input}
            data-cy={`${formName}.partnerName`}
            disabled={!!partnerId}
            label="Name *"
            name={`${formName}.partnerName`}
            validate={required}
            wrapperSx={{
              ...wrapperStyled,
            }}
          />
          <Field
            component={Input}
            data-cy={`${formName}.email`}
            label="Email *"
            name={`${formName}.email`}
            validate={composeValidations(required, email)}
            wrapperSx={{
              ...wrapperStyled,
            }}
          />
          <Field
            component={Input}
            data-cy={`${formName}.address`}
            label="Address *"
            name={`${formName}.address`}
            validate={required}
            wrapperSx={{
              ...wrapperStyled,
            }}
          />
          <Field
            component={InputMaskedIp}
            data-cy={`${formName}.origin`}
            label="DNS / IP Address *"
            name={`${formName}.origin`}
            validate={composeValidations(required, isValidIpAddress)}
            wrapperSx={{
              ...wrapperStyled,
            }}
          />
          <Field
            component={SingleDatePicker}
            data-cy={`${formName}.startDate`}
            label="Starting Date *"
            minDate={new Date()}
            name={`${formName}.startDate`}
            validate={required}
            wrapperSx={{
              ...wrapperStyled,
            }}
          />
          <Field
            component={Dropdown}
            data-cy={`${formName}.censusFrequency`}
            label="Census Frequency *"
            name={`${formName}.censusFrequency`}
            options={[
              { label: 'Daily', value: 'daily' },
              { label: 'Weekly', value: 'weekly' },
              { label: 'Monthly', value: 'monthly' },
              { label: 'Quarterly', value: 'quarterly' },
            ]}
            validate={required}
            wrapperSx={{
              ...wrapperStyled,
            }}
          />
        </Box>
      </Card>
    </>
  );
};

PartnerDetailsForm.propTypes = {
  formName: PropTypes.string.isRequired,
  partnerId: PropTypes.string,
};

PartnerDetailsForm.defaultProps = {
  partnerId: null,
};

export default PartnerDetailsForm;
