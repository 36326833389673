import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import ReactGA from 'react-ga';
import { sentenceCase } from 'change-case';
import Spinner from '@shoreag/base/Spinner';
import { get } from 'lodash';
import { AuthContext } from '@shoreag/auth';
import { FORM_ERROR } from 'final-form';
import { Form as FinalForm } from 'react-final-form';
import { useMutation, useQuery } from 'react-apollo';
import arrayMutators from 'final-form-arrays';
import stripTypename from '@shoreag/helpers/strip-typename';
import { parse } from 'query-string';
import { Location } from '@reach/router';
import FormError from '../FormError';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import createPartnerMutation from '../../graphql/mutations/create-partner.gql';
import createPartnerFileSetupMutation from '../../graphql/mutations/create-partner-file-setup.gql';
import createPartnerGroupAssociationMutation from '../../graphql/mutations/create-partner-group-association.gql';
import createPartnerEscalationNotificationsMutation from '../../graphql/mutations/create-partner-escalation-notifications.gql';
import isPermitted from '../../utilities/is-permitted';
import partnerQuery from '../../graphql/queries/partner.gql';
import updatePartnerMutation from '../../graphql/mutations/update-partner.gql';
import updatePartnerFileSetupMutation from '../../graphql/mutations/update-partner-file-setup.gql';
import updatePartnerGroupAssociationMutation from '../../graphql/mutations/update-partner-group-association.gql';
import updatePartnerEscalationNotificationsMutation from '../../graphql/mutations/update-partner-escalation-notifications.gql';
import valueMapperDefinitionQuery from '../../graphql/queries/all-value-mapper-definitions.gql';
import {
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '../../utilities/constants';
import ActionBar from '../ActionBar';
import PartnerOnBoardingSteps from '../PartnerOnBoardingSteps';
import { ONBOARDING_STEPS } from '../PartnerOnBoardingSteps/utilities/constants';
import {
  getCompletedSteps,
  parsePartner,
  setOnBoardingSteps,
} from '../PartnerOnBoardingSteps/utilities/helper';
import useSnackbar from '../../utilities/use-snackbar';
import { isValidIpAddress } from '../../utilities/validations';

const PartnerOnBoardPage = ({ id, navigate }) => {
  const { user } = useContext(AuthContext);
  const [setSnack] = useSnackbar();
  const [partnerValues, setPartnerValues] = useState(null);

  if (
    !isPermitted({
      desiredAction: PERMISSION_ACTIONS.W,
      desiredResource: PERMISSION_RESOURCES.TRADING_PARTNERS,
      ...user,
    })
  ) {
    return <NotFoundPage />;
  }

  const [createPartner] = useMutation(createPartnerMutation);
  const [createPartnerFileSetup] = useMutation(createPartnerFileSetupMutation);
  const [createPartnerGroupAssociation] = useMutation(
    createPartnerGroupAssociationMutation
  );
  const [createPartnerEscalationNotifications] = useMutation(
    createPartnerEscalationNotificationsMutation
  );
  const [updatePartner] = useMutation(updatePartnerMutation);
  const [updatePartnerFileSetup] = useMutation(updatePartnerFileSetupMutation);
  const [updatePartnerGroupAssociation] = useMutation(
    updatePartnerGroupAssociationMutation
  );
  const [updatePartnerEscalationNotifications] = useMutation(
    updatePartnerEscalationNotificationsMutation
  );

  const { data, loading: vmDefinitionLoading } = useQuery(
    valueMapperDefinitionQuery
  );
  const valueMapperDefinitions = get(data, 'allValueMapperDefinitions', []);

  const { data: partnerData, loading } = useQuery(partnerQuery, {
    fetchPolicy: 'cache-and-network',
    skip: !id,
    variables: { id },
  });

  const partner = get(partnerData, 'partner', {});

  const handleNextStep = (stepObj) => {
    const { currentStepIndex, newId, updateId } = stepObj;
    const currentStepName = ONBOARDING_STEPS[currentStepIndex].name;
    if (currentStepName) {
      setSnack(
        `${sentenceCase(currentStepName)} ${
          newId ? 'created' : 'saved'
        } Successfully!`
      );
    }
    if (parseInt(currentStepIndex, 10) === ONBOARDING_STEPS.length - 1) {
      navigate('/partners/');
    } else {
      navigate(
        `/partners/${newId || updateId}/edit?step=${
          parseInt(currentStepIndex, 10) + 1
        }`
      );
    }
  };

  const addUpdatePartnerData = async ({ currentStepName, values, action }) => {
    let resultData = {};
    const currentStepPayload = stripTypename({
      variables: {
        [currentStepName]: values[currentStepName],
        partnerId: values.partnerId,
      },
    });
    switch (currentStepName) {
      case 'partnerDetailsAndContacts': {
        if (action === 'update') {
          const {
            data: { updatePartner: updatedData },
          } = await updatePartner(currentStepPayload);
          resultData = { ...updatedData };
        } else {
          const {
            data: { createPartner: createdData },
          } = await createPartner(currentStepPayload);
          resultData = { ...createdData };
        }
        break;
      }
      case 'fileSetupSchemaAndApi': {
        if (action === 'update') {
          const {
            data: { updatePartnerFileSetup: updatedData },
          } = await updatePartnerFileSetup(currentStepPayload);
          resultData = { ...updatedData };
        } else {
          const {
            data: { createPartnerFileSetup: createdData },
          } = await createPartnerFileSetup(currentStepPayload);
          resultData = { ...createdData };
        }
        break;
      }
      case 'groupAssociation': {
        if (action === 'update') {
          const {
            data: { updatePartnerGroupAssociation: updatedData },
          } = await updatePartnerGroupAssociation(currentStepPayload);
          resultData = { ...updatedData };
        } else {
          const {
            data: { createPartnerGroupAssociation: createdData },
          } = await createPartnerGroupAssociation(currentStepPayload);
          resultData = { ...createdData };
        }
        break;
      }
      case 'escalationNotifications': {
        if (action === 'update') {
          const {
            data: { updatePartnerEscalationNotifications: updatedData },
          } = await updatePartnerEscalationNotifications(currentStepPayload);
          resultData = { ...updatedData };
        } else {
          const {
            data: { createPartnerEscalationNotifications: createdData },
          } = await createPartnerEscalationNotifications(currentStepPayload);
          resultData = { ...createdData };
        }
        break;
      }
      default:
        return null;
    }
    return { resultData };
  };

  const isLoading = loading || vmDefinitionLoading;

  return (
    <Location>
      {({ location }) => {
        const queryParams = parse(location.search);
        const currentStepIndex = get(queryParams, 'step', 0);
        const currentStepName = ONBOARDING_STEPS[currentStepIndex].name;
        const partnerInitValues = parsePartner({
          partner: partnerValues || partner,
          valueMapperDefinitions,
        });
        const { completedStep } = partnerInitValues;
        const completedSteps = completedStep
          ? getCompletedSteps(completedStep)
          : [];
        const partnerName = get(
          partnerInitValues,
          'partnerDetailsAndContacts.partnerInformation.partnerName',
          ''
        );

        const fileSetupSchemaAndApi = get(
          partnerInitValues,
          'fileSetupSchemaAndApi',
          null
        );
        const deliveryDetails = get(
          partnerInitValues,
          'fileSetupSchemaAndApi.deliveryDetails',
          []
        );

        const partnerDetailsAndContacts = get(
          partnerInitValues,
          'partnerDetailsAndContacts',
          null
        );
        const partnerInformation = get(
          partnerInitValues,
          'partnerDetailsAndContacts.partnerInformation',
          null
        );
        const origin = get(
          partnerInitValues,
          'partnerDetailsAndContacts.partnerInformation.origin',
          ''
        );
        const initialValues = {
          ...partnerInitValues,
          fileSetupSchemaAndApi: {
            ...fileSetupSchemaAndApi,
            deliveryDetails: [
              ...(deliveryDetails || []).map((i) => {
                if (i?.pipelineVersionId) {
                  return {
                    ...i,
                    pipelineVersionId: Number(i.pipelineVersionId),
                  };
                }
                return i;
              }),
            ],
          },
          partnerDetailsAndContacts: {
            ...partnerDetailsAndContacts,
            partnerInformation: {
              ...partnerInformation,
              origin:
                origin && isValidIpAddress(origin) === undefined
                  ? origin
                  : null,
            },
          },
        };
        return (
          <Route
            header={{
              icon: 'globe',
              title: partnerName || 'On board',
              type: 'Partner',
            }}
            isPrivate
          >
            {isLoading && <Spinner />}
            {!loading && partner && (
              <FinalForm
                initialValues={initialValues}
                keepDirtyOnReinitialize
                mutators={arrayMutators}
                onSubmit={async (values) => {
                  const updatedValues = parsePartner({
                    partner: values,
                    type: 'submit',
                  });
                  try {
                    if (completedSteps.includes(currentStepName)) {
                      const { resultData } = await addUpdatePartnerData({
                        action: 'update',
                        currentStepName,
                        values: { ...updatedValues },
                      });
                      const { partnerId } = resultData;
                      if (partnerId) {
                        ReactGA.event({
                          action: 'Trading Partner Edited',
                          category: 'Trading Partners',
                          label: id,
                        });
                        setPartnerValues({
                          ...updatedValues,
                          ...resultData,
                        });
                        handleNextStep({
                          currentStepIndex,
                          updateId: partnerId,
                        });
                      }
                    } else {
                      const { resultData } = await addUpdatePartnerData({
                        currentStepName,
                        values: { ...updatedValues },
                      });
                      const { partnerId } = resultData;
                      if (partnerId) {
                        ReactGA.event({
                          action: 'Trading Partner Created',
                          category: 'Trading Partners',
                          label: id,
                        });
                        setPartnerValues({
                          ...updatedValues,
                          ...resultData,
                        });
                        handleNextStep({ currentStepIndex, newId: partnerId });
                      }
                    }
                  } catch (e) {
                    return {
                      [FORM_ERROR]: get(
                        e,
                        'graphQLErrors[0].message',
                        e.message
                      ),
                    };
                  }
                }}
                render={(formContext) => (
                  <form onSubmit={formContext.handleSubmit}>
                    <PartnerOnBoardingSteps
                      currentStepIndex={currentStepIndex}
                      formContext={formContext}
                      onStepSelect={(stepIndex) =>
                        navigate(
                          `/partners/${id}/edit?step=${parseInt(stepIndex, 10)}`
                        )
                      }
                      partnerId={id}
                      steps={setOnBoardingSteps(partnerValues || partner)}
                    />
                    <FormError>{formContext.submitError}</FormError>
                    <ActionBar
                      leftButtonProps={{
                        children: 'Cancel',
                        onClick: () => navigate('/partners/'),
                      }}
                      rightButtonProps={{
                        children: 'Save',
                        'data-cy': 'save',
                        submitting: formContext.submitting,
                        sx: {
                          ml: 5,
                        },
                        type: 'submit',
                      }}
                      sx={{
                        justifyContent: 'center',
                        zIndex: null,
                      }}
                    />
                  </form>
                )}
              />
            )}
          </Route>
        );
      }}
    </Location>
  );
};

PartnerOnBoardPage.propTypes = {
  id: PropTypes.string,
  navigate: PropTypes.func.isRequired,
};

PartnerOnBoardPage.defaultProps = {
  id: null,
};

export default PartnerOnBoardPage;
