import React, { useContext } from 'react';
import Card from '@shoreag/base/Card';
import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import Icon from '../Icon';

const StepCard = ({ step, isActive, isDisabled, onClick, sx }) => {
  const theme = useContext(ThemeContext);
  const { icon, displayName, optional, isIncomplete } = step;
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        minHeight: '145px',
        px: 4,
      }}
    >
      <Card
        onClick={onClick}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          flex: 'auto',
          flexDirection: 'column',
          minHeight: '7.5rem',
          mt: 0,
          px: 2,
          py: 4,
          textAlign: 'center',
          ...(isActive
            ? {
                boxShadow: `0 0 0 2px ${theme.colors.accent} inset`,
                cursor: 'default',
              }
            : {}),
          ...(isDisabled
            ? {
                opacity: '0.5',
                pointerEvents: 'none',
              }
            : {}),
          ...sx,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            bg: 'accent',
            borderRadius: '50%',
            display: 'flex',
            height: '2.5rem',
            justifyContent: 'center',
            width: '2.5rem',
          }}
        >
          <Icon color="white" mt={1} svg={icon || 'repeat'} width="1rem" />
        </Box>
        <Box fontWeight="bold" mt="4">
          {displayName}
          {optional && <Box sx={{ fontSize: 1 }}>(Optional)</Box>}
        </Box>
        <Box
          sx={{
            color: isIncomplete ? 'text.subtle' : 'success',
            fontSize: 1,
            mt: 'auto',
            pt: 2,
          }}
        >
          {isIncomplete ? 'Incomplete' : 'Completed'}
        </Box>
      </Card>
    </Box>
  );
};

StepCard.propTypes = {
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  step: PropTypes.shape({
    displayName: PropTypes.string,
    icon: PropTypes.string,
    isIncomplete: PropTypes.bool,
    name: PropTypes.string,
    optional: PropTypes.bool,
  }).isRequired,
  sx: PropTypes.shape({}),
};

StepCard.defaultProps = {
  isActive: false,
  isDisabled: false,
  onClick: () => null,
  sx: {},
};

export default StepCard;
