import React, { useState, useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@shoreag/base/Box';
import { ThemeContext } from 'styled-components';
import Spinner from '@shoreag/base/Spinner';
import parseUuid from '@shoreag/helpers/parse-uuid';
import { useQuery } from 'react-apollo';
import { get } from 'lodash';
// import schemaQuery from '../../../../graphql/queries/schema.gql';
import allRecursiveSchemaDatumQuery from '../../../../graphql/queries/all-recursive-schema-datum.gql';
import MappingValuesCard from '../../../MappingValuesCard';
// import parseSchemaDataTreeView from '../../../../utilities/parse-schema-data-tree-view';
// import PillsGroup from '../../../PillsGroup';

const FileIdentifierSelector = ({
  formContext,
  formName,
  wrapperSx,
  selected,
}) => {
  const fileIdentifierFieldName = `${
    formName ? `${formName}.` : ''
  }fileIdentifier`;

  const {
    defaultStyles: { fieldLabel },
  } = useContext(ThemeContext);

  const schemaId = get(formContext.values, `${formName}.schemaId`, '');
  const versionId = get(formContext.values, `${formName}.versionId`, '');
  const schemaWithId = schemaId && versionId && [schemaId, versionId].join(':');

  const [selectedParentSchema, setSelectedParentSchema] = useState(null);
  const [parentSchema, setParentSchema] = useState(null);

  // const { data: schemaData, loading: isFetching } = useQuery(schemaQuery, {
  //   fetchPolicy: 'network-only',
  //   variables: { id: schemaWithId },
  // });

  const {
    data: allRecursiveSchemaDatumData,
    loading: isRecursiveLoading,
  } = useQuery(allRecursiveSchemaDatumQuery, {
    skip: !schemaWithId,
    variables: { id: schemaWithId },
  });

  const fieldSortFunction = (a, b) =>
    a.fieldName.toLowerCase() > b.fieldName.toLowerCase() ? 1 : -1;

  const fuzzyUuidMatch = (id1, id2) =>
    parseUuid(id1).id === parseUuid(id2).id &&
    Number(parseUuid(id1).version) === Number(parseUuid(id2).version);

  const recursivelyMapDatums = (datums, schemaId, pathPrefix = '') =>
    datums.reduce(
      (fieldNames, datum) =>
        fuzzyUuidMatch(datum.schemaVersionId, schemaId)
          ? fieldNames.concat(
              datum.definitionType === 'object'
                ? recursivelyMapDatums(
                    datums,
                    datum.nestedSchemaWithVersion.id,
                    `${pathPrefix}${datum.fieldName}${
                      datum.multiple ? '[]' : ''
                    }.`
                  )
                : [
                    {
                      definitionType: datum.definitionType,
                      fieldName: `${pathPrefix}${datum.fieldName}`,
                      id: datum.id,
                    },
                  ]
            )
          : fieldNames,
      []
    );

  // useEffect(() => {
  // if (schemaData) {
  // const treeGraphViewSchemaData = parseSchemaDataTreeView({
  //   datasetSchema: get(schemaData, 'datasetSchema', {}),
  //   nodePath: '',
  //   version: versionId,
  // });
  // const hasNodeChild = some(treeGraphViewSchemaData.children, (child) =>
  //   isObject(values(child)[0]?.nodeChild)
  // );
  // setIsNestedSchema(hasNodeChild);
  // }
  // }, [schemaData, versionId]);

  const removeMatchingAttributes = (obj, paths) => {
    const result = {};
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (value?.path && paths.some((path) => path === value.path)) {
        return;
      }
      if (typeof value === 'object' && !Array.isArray(value)) {
        result[key] = removeMatchingAttributes(value, paths);
      } else {
        result[key] = value;
      }
    });
    return result;
  };

  useEffect(() => {
    if (parentSchema) {
      const updatedParentSchema = { ...parentSchema };
      removeMatchingAttributes(updatedParentSchema, selected);
      setSelectedParentSchema(updatedParentSchema);
    } else {
      setSelectedParentSchema(null);
    }
  }, [selected, parentSchema]);

  const mappedData = useMemo(() => {
    if (!allRecursiveSchemaDatumData) return [];
    const allRecursiveSchemaDatum = get(
      allRecursiveSchemaDatumData,
      'allRecursiveSchemaDatum',
      []
    );
    return recursivelyMapDatums(allRecursiveSchemaDatum, schemaWithId)
      .filter((i) => !selected.includes(i.fieldName))
      .sort(fieldSortFunction);
  }, [allRecursiveSchemaDatumData, schemaWithId, selected]);

  let content = null;

  if (isRecursiveLoading) {
    content = <Spinner justifyContent="flex-start" mt={5} py="md" />;
  }
  if (mappedData.length > 0) {
    content = (
      <>
        <Box sx={fieldLabel}>Schema Columns</Box>
        <MappingValuesCard
          data={mappedData}
          disabledDatums={selected}
          emptyMessage="All Columns are selected"
          onDatumSelect={({ fieldName }) => {
            formContext.form.change(fileIdentifierFieldName, [
              ...selected,
              fieldName,
            ]);
          }}
          onSchemaSelect={(schema) => {
            setParentSchema(schema);
          }}
          searchName="schemas"
          selectedData={selectedParentSchema}
        />
      </>
    );
  }

  return <Box sx={wrapperSx}>{content}</Box>;
};

FileIdentifierSelector.propTypes = {
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }),
  formName: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  wrapperSx: PropTypes.shape({}),
};

FileIdentifierSelector.defaultProps = {
  formContext: {},
  selected: [],
  wrapperSx: {},
};

export default FileIdentifierSelector;
