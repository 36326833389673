import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import Input from '@shoreag/base/Input';
import { composeValidations, required } from '@shoreag/validations';
import AllUsersDropdown from '../../../AllUsersDropdown';
import { positive } from '../../../../utilities/validations';

const GroupAssociationDefaultFields = ({ formName }) => {
  const wrapperStyled = {
    flex: 'auto',
    maxWidth: ['100%', null, null, '50%', '25%'],
    mb: 6,
    mt: 0,
    px: 4,
    width: '100%',
  };
  return (
    <>
      <Field
        component={Input}
        data-cy={`${formName}.company`}
        label="Company Name *"
        name={`${formName}.company`}
        validate={required}
        wrapperSx={{
          ...wrapperStyled,
        }}
      />
      <Field
        component={Input}
        data-cy={`${formName}.customerNumber`}
        label="customer Number *"
        name={`${formName}.customerNumber`}
        validate={required}
        wrapperSx={{
          ...wrapperStyled,
        }}
      />
      <AllUsersDropdown
        data-cy={`${formName}.brokerAssociation`}
        expectedValue="id"
        isRequired
        label="Broker Association *"
        name={`${formName}.brokerAssociation`}
        wrapperSx={{
          ...wrapperStyled,
        }}
      />
      <Field
        component={Input}
        data-cy={`${formName}.employeeCount`}
        label="Employee Count *"
        name={`${formName}.employeeCount`}
        validate={composeValidations(required, positive)}
        wrapperSx={{
          ...wrapperStyled,
        }}
      />
    </>
  );
};

GroupAssociationDefaultFields.propTypes = {
  formName: PropTypes.string.isRequired,
};

GroupAssociationDefaultFields.defaultProps = {};

export default GroupAssociationDefaultFields;
