import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import Input from '@shoreag/base/Input';
import { Field } from 'react-final-form';
import AllUsersDropdown from '../../AllUsersDropdown';
import { CONTACT_TYPE } from '../utilities/constants';

const RelationshipContactsForm = ({ formName }) => {
  const wrapperStyled = {
    flex: 'auto',
    maxWidth: ['100%', null, null, '50%'],
    mt: 0,
    p: 4,
    width: '100%',
  };
  return (
    <>
      <Box as="h2" sx={{ mb: 4, mt: 6 }}>
        Relationship Contacts
      </Box>
      <Card>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            m: -4,
          }}
        >
          <AllUsersDropdown
            data-cy={`${formName}.primaryContact`}
            expectedValue="id"
            isRequired
            label="Primary Contact *"
            name={`${formName}.primaryContact`}
            wrapperSx={{
              ...wrapperStyled,
            }}
          />
          <AllUsersDropdown
            data-cy={`${formName}.secondaryContact`}
            expectedValue="id"
            isClearable
            isRequired={false}
            label="Secondary Contact"
            name={`${formName}.secondaryContact`}
            wrapperSx={{
              ...wrapperStyled,
            }}
          />
          <Field
            component={Input}
            data-cy={`${formName}.contactType`}
            defaultValue={CONTACT_TYPE.RELATIONSHIP}
            name={`${formName}.contactType`}
            type="hidden"
          />
        </Box>
      </Card>
    </>
  );
};

RelationshipContactsForm.propTypes = {
  formName: PropTypes.string.isRequired,
};

export default RelationshipContactsForm;
