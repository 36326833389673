import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { required } from '@shoreag/validations';
import Input from '@shoreag/base/Input';
import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import Dropdown from '@shoreag/base/Dropdown';
import get from 'lodash/get';
import {
  API_METHODS_OPTIONS,
  AUTHENTICATION_TYPE,
  AUTHENTICATION_TYPE_OPTIONS,
  FILE_FORMAT_OPTIONS,
} from '../../../utilities/constants';
import KeyValuePairForm from '../../KeyValuePairForm';

const AuthenticationDetailsForm = ({ formContext, formName }) => {
  const wrapperStyled = {
    flex: 'auto',
    maxWidth: ['100%', null, null, '50%', '33.33%'],
    mb: 6,
    mt: 0,
    px: 4,
    width: '100%',
  };
  const isOAuth =
    get(formContext.values, `${formName}.authType`) ===
    AUTHENTICATION_TYPE.O_AUTH;
  return (
    <>
      <Box as="h2" sx={{ mb: 4, mt: 6 }}>
        Authentication Details
      </Box>
      <Card>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            m: -4,
          }}
        >
          <Field
            component={Dropdown}
            data-cy={`${formName}.authType`}
            label="Authentication Type *"
            name={`${formName}.authType`}
            options={AUTHENTICATION_TYPE_OPTIONS}
            validate={required}
            wrapperSx={{
              ...wrapperStyled,
            }}
          />
          {isOAuth ? (
            <>
              <Field
                component={Dropdown}
                data-cy={`${formName}.authPayloadType`}
                label="Auth payload Type *"
                name={`${formName}.authPayloadType`}
                options={FILE_FORMAT_OPTIONS.filter((fileFormat) =>
                  ['json', 'xml'].includes(fileFormat.value)
                )}
                validate={required}
                wrapperSx={{
                  ...wrapperStyled,
                }}
              />
              <KeyValuePairForm
                addButtonLabel="Add payload parameters"
                allowDefaultOne
                divider={isOAuth}
                formContext={formContext}
                formName={`${formName}.authPayload`}
                keyLabel="Payload parameter"
              />
              <Field
                component={Dropdown}
                data-cy={`${formName}.apiMethod`}
                label="API Method *"
                name={`${formName}.apiMethod`}
                options={API_METHODS_OPTIONS}
                validate={required}
                wrapperSx={{
                  ...wrapperStyled,
                  maxWidth: ['100%', null, null, '33.33%'],
                }}
              />
              <Field
                component={Input}
                data-cy={`${formName}.tokenEndPointUrl`}
                label="Token endpoint URL *"
                name={`${formName}.tokenEndPointUrl`}
                placeholder="Enter token end point url"
                validate={required}
                wrapperSx={{
                  ...wrapperStyled,
                  maxWidth: ['100%', null, null, '66.66%'],
                }}
              />
              <KeyValuePairForm
                addButtonLabel="Add URL parameters"
                divider={false}
                formContext={formContext}
                formName={`${formName}.params`}
                keyLabel="Header parameter"
              />
            </>
          ) : (
            <>
              <Field
                component={Input}
                data-cy={`${formName}.userName`}
                label="User Name *"
                name={`${formName}.userName`}
                validate={required}
                wrapperSx={{
                  ...wrapperStyled,
                }}
              />
              <Field
                component={Input}
                data-cy={`${formName}.password`}
                label="Password *"
                name={`${formName}.password`}
                validate={required}
                wrapperSx={{
                  ...wrapperStyled,
                }}
              />
            </>
          )}
        </Box>
      </Card>
    </>
  );
};

AuthenticationDetailsForm.propTypes = {
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }).isRequired,
  formName: PropTypes.string.isRequired,
};

export default AuthenticationDetailsForm;
