import { Field } from 'react-final-form';
import Input from '@shoreag/base/Input';
import { required } from '@shoreag/validations';
import Dropdown from '@shoreag/base/Dropdown';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Box from '@shoreag/base/Box';
import {
  API_METHODS,
  API_METHODS_OPTIONS,
  API_MODES,
  API_MODES_OPTIONS,
  API_TYPES,
  API_TYPES_OPTIONS,
  END_OF_NAME_REGEX,
} from '../../../../utilities/constants';
import SchemaDropdown from '../../../SchemaDropdown';
import KeyValuePairForm from '../../../KeyValuePairForm';
import config from '../../../../config.json';
import PipelineDropdown from '../../../PipelineDetailsFormSection/PipelineDropdown';

const APIForm = ({ formContext, formName }) => {
  const wrapperStyled = {
    flex: 'auto',
    maxWidth: ['100%', null, null, '50%', '33.33%'],
    mb: 6,
    mt: 0,
    px: 4,
    width: '100%',
  };
  const apiMode = get(formContext.values, `${formName}.apiMode`);
  const isApiModeExpose = apiMode === API_MODES.EXPOSE;
  const selectedApiType = get(formContext.values, `${formName}.apiType`);
  const iSApiTypeEnrollment = selectedApiType === API_TYPES.ENROLLMENT;

  const deliveryDetailsLocation = formName.replace(
    END_OF_NAME_REGEX,
    '.deliveryDetails'
  );
  const selectedApiTypes = get(formContext.values, deliveryDetailsLocation, [])
    .map((i) => i.apiType)
    .filter((i) => i);

  if (isApiModeExpose) {
    formContext.form.change(
      `${formName}.apiMethod`,
      iSApiTypeEnrollment ? API_METHODS.POST : API_METHODS.GET
    );
    formContext.form.change(
      `${formName}.endpointUrl`,
      `${config.apiHub}/${selectedApiType}`
    );
  }

  return (
    <>
      <Field
        component={Dropdown}
        data-cy={`${formName}.apiType`}
        label="API Type *"
        name={`${formName}.apiType`}
        options={API_TYPES_OPTIONS.filter(
          (i) =>
            i.value === selectedApiType || !selectedApiTypes.includes(i.value)
        )}
        validate={required}
        wrapperSx={{
          ...wrapperStyled,
          maxWidth: ['100%', null, null, '50%', '33.33%'],
        }}
      />
      <Field
        component={Dropdown}
        data-cy={`${formName}.apiMode`}
        label="API Mode *"
        name={`${formName}.apiMode`}
        options={API_MODES_OPTIONS}
        validate={required}
        wrapperSx={{
          ...wrapperStyled,
          maxWidth: ['100%', null, null, '50%', '33.33%'],
        }}
      />
      {apiMode && (
        <>
          <Field
            component={Dropdown}
            data-cy={`${formName}.apiMethod`}
            disabled={!!isApiModeExpose}
            label="API Method *"
            name={`${formName}.apiMethod`}
            options={API_METHODS_OPTIONS}
            validate={required}
            wrapperSx={{
              ...wrapperStyled,
              maxWidth: ['100%', null, null, '50%', '33.33%'],
            }}
          />
        </>
      )}
      {apiMode === API_MODES.EXPOSE && (
        <>
          <Field
            component={Input}
            data-cy={`${formName}.endpointUrl`}
            disabled={!!apiMode}
            label="DEX Generated Url *"
            name={`${formName}.endpointUrl`}
            validate={required}
            wrapperSx={{
              ...wrapperStyled,
              maxWidth: ['100%', null, null, '50%', '66.66%'],
            }}
          />
        </>
      )}
      {apiMode === API_MODES.CALLBACK && (
        <>
          <Field
            component={Input}
            data-cy={`${formName}.endpointUrl`}
            label="End Point Url *"
            name={`${formName}.endpointUrl`}
            placeholder="Enter end point url"
            validate={required}
            wrapperSx={{
              ...wrapperStyled,
              maxWidth: ['100%', null, null, null, '66.66%'],
            }}
          />
          <KeyValuePairForm
            // allowDefaultOne
            addButtonLabel="Add URL parameters"
            formContext={formContext}
            formName={`${formName}.params`}
            keyLabel="Header parameter"
          />
        </>
      )}
      {apiMode && (
        <Box
          sx={{
            '& > div': {
              '&:first-child': {
                maxWidth: ['100%', null, null, '66.66%'],
              },
            },
            display: 'flex',
            width: '100%',
          }}
        >
          <SchemaDropdown
            filedProps={{
              wrapperSx: { ...wrapperStyled },
            }}
            formContext={formContext}
            formName={formName}
            hideSpinner
            isRequired
          />
        </Box>
      )}
      {selectedApiType === API_TYPES.ENROLLMENT && (
        <Box
          sx={{
            '& > div': {
              '&:first-child': {
                maxWidth: ['100%', null, null, '66.66%'],
              },
            },
            display: 'flex',
            width: '100%',
          }}
        >
          <PipelineDropdown
            filedProps={{
              wrapperSx: { ...wrapperStyled },
            }}
            formContext={formContext}
            formName={formName}
            hideSpinner
            pipelineFieldName="pipelineCode"
            pipelineVersionFieldName="pipelineVersionId"
          />
        </Box>
      )}
    </>
  );
};

APIForm.propTypes = {
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }),
  formName: PropTypes.string.isRequired,
};

APIForm.defaultProps = {
  formContext: {},
};

export default APIForm;
