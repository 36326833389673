import Dropdown from '@shoreag/base/Dropdown';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '@shoreag/base/Spinner';
import get from 'lodash/get';
import { Field } from 'react-final-form';
import { Query } from 'react-apollo';
import { required } from '@shoreag/validations';
import schemaQuery from '../../graphql/queries/schema.gql';

const SchemaVersionsDropdown = ({
  isRequired,
  schemaId,
  disabled,
  formName,
  filedProps,
  hideSpinner,
}) => (
  <Query query={schemaQuery} skip={!schemaId} variables={{ id: schemaId }}>
    {({ loading, data }) => {
      if (!hideSpinner && loading) return <Spinner />;
      const latestVersionNumber = get(
        data,
        'datasetSchema.latestVersionNumber',
        ''
      );
      return (
        <Field
          component={Dropdown}
          data-cy={formName}
          disabled={disabled || loading}
          isLoading={loading}
          label={`Schema Version ${isRequired ? '*' : ''}`}
          name={formName}
          options={Array.from(
            { length: latestVersionNumber },
            (_, index) => latestVersionNumber - index
          ).map((versionNumber) => ({
            label: `${versionNumber}`,
            value: `${versionNumber}`,
          }))}
          validate={isRequired ? required : null}
          {...filedProps}
        />
      );
    }}
  </Query>
);

SchemaVersionsDropdown.propTypes = {
  disabled: PropTypes.bool,
  filedProps: PropTypes.shape({}),
  formName: PropTypes.string,
  hideSpinner: PropTypes.bool,
  isRequired: PropTypes.bool.isRequired,
  schemaId: PropTypes.string,
};

SchemaVersionsDropdown.defaultProps = {
  disabled: false,
  filedProps: {},
  formName: '',
  hideSpinner: false,
  schemaId: null,
};

export default SchemaVersionsDropdown;
