import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import React, { useContext } from 'react';
import Table from '@shoreag/base/Table';
import get from 'lodash/get';
import { useQuery } from 'react-apollo';
import { AuthContext } from '@shoreag/auth';
import { TooltipBox, TooltipTrigger } from '@shoreag/base/Tooltip';
import formatDateTimeConcise from '@shoreag/helpers/format-date-time-concise';
import { capitalCase } from 'change-case';
import ToolbarButton from '../ToolbarButton';
import DotsIcon from '../DotsIcon';
import Route from '../Route';
import allPartner from '../../graphql/queries/all-partner.gql';
import config from '../../config.json';
import setActionDropdown from '../../utilities/set-action-dropdown';
import isPermitted from '../../utilities/is-permitted';
import {
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '../../utilities/constants';
import LinkWithVersion from '../LinkWithVersion';
import tradingPartners from '../../utilities/get-trading-partners';
import CircularProgressBar from '../CircularProgressBar';
import {
  ESCALATION_LEVEL_COLOR_MAP,
  ESCALATION_LEVEL_LABEL,
  ONBOARDING_STEPS,
} from '../PartnerOnBoardingSteps/utilities/constants';
import { getCompletedSteps } from '../PartnerOnBoardingSteps/utilities/helper';

const PartnerDashboardPage = () => {
  const { user } = useContext(AuthContext);

  const { data, loading } = useQuery(allPartner, {
    fetchPolicy: 'network-only',
  });

  const partners = tradingPartners(get(data, 'allPartner', []), user);

  const hasWritePermission = isPermitted({
    desiredAction: PERMISSION_ACTIONS.W,
    desiredResource: PERMISSION_RESOURCES.TRADING_PARTNERS,
    ...user,
  });

  const setPartnerStatus = (partner) => {
    const completedSteps = getCompletedSteps(partner.completedStep);
    const allCompleted = completedSteps.length === ONBOARDING_STEPS.length;
    const percentage = (completedSteps.length / ONBOARDING_STEPS.length) * 100;
    return allCompleted ? (
      <>{partner.isDeleted ? 'Inactive' : 'Active'}</>
    ) : (
      <TooltipTrigger
        as="span"
        data-tip={`${completedSteps.length} / ${ONBOARDING_STEPS.length}`}
        id="tooltip"
      >
        <CircularProgressBar
          percentage={percentage}
          sx={{ height: '1.75rem', width: '1.75rem' }}
        />
      </TooltipTrigger>
    );
  };

  return (
    <Route
      header={{
        icon: 'globe',
        rightContainer: hasWritePermission && (
          <ToolbarButton
            icon="plus"
            label="On board a Partner"
            link="/partners/on-board"
          />
        ),
        title: 'Dashboard',
        type: 'Partner',
      }}
      isPrivate
    >
      <Card p={0}>
        <Table
          header={[
            'Partner Name',
            'Email',
            'Address',
            'Census Frequency',
            'Start Date',
            'Escalation Status',
            'Status',
            '',
          ]}
          isLoading={loading}
          rows={partners.map((partner) => {
            const {
              id,
              email,
              partnerName,
              address,
              censusFrequency,
              startDate,
              isDeleted,
              escalationLevel,
            } = partner;
            const dashboardFilters = encodeURIComponent(`partner=${id}`);

            const { dropdownItems, dropdownActions } = setActionDropdown([
              {
                action: `/partners/${id}`,
                display: true,
                label: ['view', 'View'],
              },
              {
                action: `/partners/${id}/edit`,
                display: hasWritePermission,
                label: ['edit', 'Edit'],
              },
              {
                action: `/schemas/?filters=${dashboardFilters}`,
                display: true,
                label: ['lists', 'View Schemas'],
              },
              {
                action: `/datasets/?filters=${dashboardFilters}`,
                display: true,
                label: ['documents', 'View Datasets'],
              },
              {
                action: `/${config.nomenclature.pipelines}/?filters=${dashboardFilters}`,
                display: true,
                label: ['bar-chart', `View ${config.nomenclature.Pipelines}`],
              },
            ]);

            return [
              <LinkWithVersion
                ellipsis={false}
                link={`/partners/${id}`}
                name={partnerName}
              />,
              email || '-',
              address || '-',
              censusFrequency || '-',
              startDate ? formatDateTimeConcise(startDate) : '-',
              escalationLevel ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <TooltipTrigger
                    as="span"
                    data-tip={capitalCase(
                      escalationLevel || ESCALATION_LEVEL_LABEL.NORMAL
                    )}
                    id="tooltip"
                  >
                    <Box
                      sx={{
                        bg:
                          ESCALATION_LEVEL_COLOR_MAP[
                            escalationLevel || ESCALATION_LEVEL_LABEL.NORMAL
                          ],
                        borderRadius: '9999px',
                        height: '7px',
                        width: '7px',
                      }}
                    />
                  </TooltipTrigger>
                </Box>
              ) : (
                '-'
              ),
              isDeleted ? 'Inactive' : setPartnerStatus(partner),
              <Box sx={{ float: 'right' }}>
                <DropdownMenu
                  button={<DotsIcon />}
                  itemActions={dropdownActions}
                  items={dropdownItems}
                />
              </Box>,
            ];
          })}
        />
        <TooltipBox id="tooltip" />
      </Card>
    </Route>
  );
};

export default PartnerDashboardPage;
