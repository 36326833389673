import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const ActionBar = ({
  extraButtonProps,
  leftButtonProps,
  rightButtonProps,
  isFixed,
  sx,
}) => {
  const {
    sizes: { widths },
  } = useContext(ThemeContext);

  const actionBarFixedSx = isFixed
    ? {
        '.sidebarIsOpen &': {
          transform: `translateX(calc(${widths.sidebar} / 2))`,
        },
        bg: 'white',
        borderColor: 'grays.2',
        borderTopStyle: 'solid',
        borderWidth: '1px',
        bottom: 0,
        boxShadow: 5,
        left: 0,
        p: 5,
        position: 'fixed',
        width: '100%',
        zIndex: 2,
      }
    : {};
  return (
    <>
      {isFixed && (
        <Box
          sx={{
            height: (t) => `calc(${t.sizes.heights.input} + ${t.space[5]} * 2)`,
          }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          ...actionBarFixedSx,
          ...sx,
        }}
      >
        <Button variant="buttons.cancel" {...leftButtonProps} />
        <Button {...rightButtonProps} />
        {extraButtonProps && <Button ml={5} {...extraButtonProps} />}
      </Box>
    </>
  );
};

ActionBar.propTypes = {
  extraButtonProps: PropTypes.shape({}),
  isFixed: PropTypes.bool,
  leftButtonProps: PropTypes.shape({}).isRequired,
  rightButtonProps: PropTypes.shape({}).isRequired,
  sx: PropTypes.shape({}),
};

ActionBar.defaultProps = {
  extraButtonProps: null,
  isFixed: true,
  sx: {},
};

export default ActionBar;
