import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Box from '@shoreag/base/Box';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { ThemeContext } from 'styled-components';

const CircularProgressBar = ({
  sx,
  buildSx,
  percentage,
  strokeWidth,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  const buildStylesSx = {
    pathColor: theme.colors.accent,
    textSize: '0',
    trailColor: theme.colors.grays[3],
    ...buildSx,
  };
  return (
    <Box sx={sx}>
      <CircularProgressbar
        strokeWidth={strokeWidth}
        styles={buildStyles(buildStylesSx)}
        text={`${percentage}%`}
        value={percentage}
        {...rest}
      />
    </Box>
  );
};

CircularProgressBar.propTypes = {
  buildSx: PropTypes.shape({}),
  percentage: PropTypes.number,
  strokeWidth: PropTypes.number,
  sx: PropTypes.shape({}),
};

CircularProgressBar.defaultProps = {
  buildSx: {},
  percentage: 0,
  strokeWidth: 20,
  sx: {},
};

export default CircularProgressBar;
