import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import { FieldArray } from 'react-final-form-arrays';
import get from 'lodash/get';
import Input from '@shoreag/base/Input';
import { Field } from 'react-final-form';
import AddButton from '../../AddButton';
import AllUsersDropdown from '../../AllUsersDropdown';
import FlagHeading from '../../FlagHeading';
import RemoveButton from '../../RemoveButton';
import { CONTACT_TYPE } from '../utilities/constants';

const BrokerContactsForm = ({
  formName,
  formContext,
  maxLimit,
  allowDefaultOne,
}) => {
  if (allowDefaultOne && !get(formContext.values, formName)) {
    formContext.form.change(formName, [{}]);
  }
  const wrapperStyled = {
    flex: 'auto',
    maxWidth: ['100%', null, null, '50%'],
    mb: 6,
    mt: 0,
    px: 4,
    width: '100%',
  };
  return (
    <Box>
      <FieldArray name={formName}>
        {({ fields }) => {
          const totalFields = fields.length;
          return (
            <>
              <Box as="h2" sx={{ mb: 4, mt: 6 }}>
                Broker Contacts
              </Box>
              {totalFields > 0 ? (
                <>
                  {fields.map((name, index) => (
                    <Card
                      key={`${name}-${index}`}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 5,
                        pb: 0,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          mx: -4,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            mb: 5,
                            px: 4,
                            width: '100%',
                          }}
                        >
                          <FlagHeading color="accent" sx={{ mt: 0 }}>
                            Contact
                            <Box
                              bg="accent"
                              borderRadius={0}
                              color="white"
                              ml={3}
                              px={2}
                              py="2px"
                            >
                              {index + 1}
                            </Box>
                          </FlagHeading>
                          {!(allowDefaultOne && totalFields === 1) ? (
                            <RemoveButton onClick={() => fields.remove(index)}>
                              Remove
                            </RemoveButton>
                          ) : null}
                        </Box>
                        <AllUsersDropdown
                          data-cy={`${name}.primaryContact`}
                          expectedValue="id"
                          isRequired
                          label="Primary Contact *"
                          name={`${name}.primaryContact`}
                          wrapperSx={{
                            ...wrapperStyled,
                          }}
                        />
                        <AllUsersDropdown
                          data-cy={`${name}.secondaryContact`}
                          expectedValue="id"
                          isRequired={false}
                          label="Secondary Contact"
                          name={`${name}.secondaryContact`}
                          wrapperSx={{
                            ...wrapperStyled,
                          }}
                        />
                        <Field
                          component={Input}
                          data-cy={`${name}.contactType`}
                          defaultValue={CONTACT_TYPE.BROKER}
                          name={`${name}.contactType`}
                          type="hidden"
                        />
                      </Box>
                    </Card>
                  ))}
                  {(!maxLimit || totalFields < maxLimit) && (
                    <AddButton
                      data-cy="addContact"
                      label="Add Contact"
                      onClick={() => fields.push({})}
                      wrapperSx={{ mt: 0 }}
                    />
                  )}
                </>
              ) : (
                <Card sx={{ display: 'flex', justifyContent: 'center' }}>
                  <AddButton
                    data-cy="addContact"
                    label="Add Contact"
                    onClick={() => fields.push({})}
                  />
                </Card>
              )}
            </>
          );
        }}
      </FieldArray>
    </Box>
  );
};

BrokerContactsForm.propTypes = {
  allowDefaultOne: PropTypes.bool,
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }),
  formName: PropTypes.string.isRequired,
  maxLimit: PropTypes.number,
};

BrokerContactsForm.defaultProps = {
  allowDefaultOne: false,
  formContext: {},
  maxLimit: null,
};

export default BrokerContactsForm;
