import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import stripTypename from '@shoreag/helpers/strip-typename';
import { sentenceCase } from 'change-case';
import { parsePartner, setOnBoardingSteps } from '../utilities/helper';
import TableWrapper from '../../TableWrapper';
import KeyValuePairsNested from '../../KeyValuePairsNested';
import ItemSlider from '../../ItemSlider';
import StepCard from '../../StepCard';
import enhanceNestedKeyValue from '../../../utilities/enhance-nested-key-value';
import { DELIVERY_DETAILS_ORDER_LIST } from '../../../utilities/constants';

const PartnerInformation = ({ data }) => {
  const steps = setOnBoardingSteps(data);
  const isViewOnly = true;
  const partnerInfo = parsePartner({
    isViewOnly,
    partner: data,
  });
  const [currentStep, setCurrentStep] = useState(null);

  const currentStepData = partnerInfo[currentStep]
    ? Object.entries(
        enhanceNestedKeyValue(stripTypename(partnerInfo[currentStep]))
      )?.sort(
        (a, b) =>
          DELIVERY_DETAILS_ORDER_LIST.indexOf(a[0]) -
          DELIVERY_DETAILS_ORDER_LIST.indexOf(b[0])
      )
    : null;

  return (
    <>
      <ItemSlider>
        {steps.map((step) => {
          const { isIncomplete, name } = step;
          return (
            <StepCard
              isActive={currentStep === name}
              isDisabled={isIncomplete}
              onClick={() => (isIncomplete ? null : setCurrentStep(name))}
              step={step}
            />
          );
        })}
      </ItemSlider>
      {currentStep && (
        <Card pt={5}>
          {partnerInfo[currentStep] ? (
            <>
              <Box as="h3" sx={{ mb: 4, mt: 6 }}>
                {sentenceCase(currentStep)}
              </Box>
              <TableWrapper>
                <KeyValuePairsNested allowTableView pairs={currentStepData} />
              </TableWrapper>
            </>
          ) : (
            'No Data'
          )}
        </Card>
      )}
    </>
  );
};

PartnerInformation.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

PartnerInformation.defaultProps = {};

export default PartnerInformation;
