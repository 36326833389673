import PropTypes from 'prop-types';
import React from 'react';
import InputMasked from '../InputMasked';

const InputMaskedIp = ({ input, ...rest }) => {
  const props = {
    guide: false,
    mask: (value) => {
      const mask = Array(value.length).fill(/[\d.]/);
      const chunks = value.split('.');

      for (let i = 0; i < 4; i += 1) {
        const chunk = chunks[i] || '';

        if (255 % +chunk === 255) {
          mask[value.length - 1] = '.';
          mask[value.length] = /[\d.]/;
        }
      }

      return mask;
    },
    pipe: (value) => {
      if (value === '.' || value.endsWith('..')) return false;

      const parts = value.split('.');

      if (
        parts.length > 4 ||
        parts.some((part) => part === '00' || part < 0 || part > 255)
      ) {
        return false;
      }

      return value;
    },
  };
  return <InputMasked id={input.name} input={input} {...props} {...rest} />;
};

InputMaskedIp.propTypes = {
  input: PropTypes.shape({ error: PropTypes.bool, name: PropTypes.string })
    .isRequired,
};

InputMaskedIp.defaultProps = {};

export default InputMaskedIp;
