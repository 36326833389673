import React from 'react';
import { Router } from '@reach/router';
import NotFoundPage from './404';
// import PartnerCreatePage from '../../components/PartnerCreatePage';
import PartnerDashboardPage from '../../components/PartnerDashboardPage';
import PartnerDetailsPage from '../../components/PartnerDetailsPage';
import PartnerOnBoardPage from '../../components/PartnerOnBoardPage';

const PartnersRouter = (props) => (
  <Router>
    <PartnerDetailsPage {...props} path="/partners/:id" />
    {/* <PartnerCreatePage {...props} path="/partners/create" /> */}
    <PartnerDashboardPage {...props} path="/partners/" />
    <PartnerOnBoardPage {...props} path="/partners/:id/edit" />
    <PartnerOnBoardPage {...props} path="/partners/on-board" />
    <NotFoundPage default />
  </Router>
);

export default PartnersRouter;
