import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ONBOARDING_STEPS_LABEL_MAP } from './utilities/constants';
import PartnerContactsForm from './PartnerContactsForm';
import PartnerDetailsForm from './PartnerDetailsForm';
import EscalationLevelForm from './EscalationLevelForm';
import RelationshipContactsForm from './RelationshipContactsForm';
import BrokerContactsForm from './BrokerContactsForm';
import GroupAssociationForm from './GroupAssociationForm';
import FileSetupForm from './FileSetupForm';
import DeliveryDetailsForm from './DeliveryDetailsForm';
import ItemSlider from '../ItemSlider';
import StepCard from '../StepCard';

const PartnerOnBoardingSteps = ({
  partnerId,
  formContext,
  steps,
  onStepSelect,
  currentStepIndex,
}) => {
  const [currentStep, setCurrentStep] = useState(
    steps[currentStepIndex || 0].name
  );

  return (
    <>
      <ItemSlider>
        {steps.map((step, index) => {
          const { name, isActive } = step;
          const isSelected = currentStep === name;
          return (
            <StepCard
              isActive={isSelected}
              isDisabled={!isActive}
              onClick={() => {
                if (!isSelected && isActive) {
                  setCurrentStep(name);
                  onStepSelect(index);
                }
              }}
              step={step}
            />
          );
        })}
      </ItemSlider>
      {currentStep ===
      ONBOARDING_STEPS_LABEL_MAP.PARTNER_DETAILS_AND_CONTACTS ? (
        <>
          <PartnerDetailsForm
            formName={`${currentStep}.partnerInformation`}
            partnerId={partnerId}
          />
          <PartnerContactsForm
            allowDefaultOne
            formContext={formContext}
            formName={`${currentStep}.partnerContact`}
          />
          <RelationshipContactsForm
            formContext={formContext}
            formName={`${currentStep}.relationship`}
          />
          <BrokerContactsForm
            formContext={formContext}
            formName={`${currentStep}.broker`}
          />
        </>
      ) : null}
      {currentStep === ONBOARDING_STEPS_LABEL_MAP.FILE_SETUP_SCHEMA_AND_API ? (
        <>
          <FileSetupForm
            allowDefaultOne
            formContext={formContext}
            formName={`${currentStep}.fileSetup`}
          />
          <DeliveryDetailsForm
            allowDefaultOne
            formContext={formContext}
            formName={`${currentStep}.deliveryDetails`}
            maxLimit={4}
          />
        </>
      ) : null}
      {currentStep === ONBOARDING_STEPS_LABEL_MAP.GROUP_ASSOCIATION ? (
        <GroupAssociationForm
          allowDefaultOne
          formContext={formContext}
          formName={`${currentStep}.groups`}
        />
      ) : null}
      {currentStep === ONBOARDING_STEPS_LABEL_MAP.ESCALATION_NOTIFICATIONS ? (
        <EscalationLevelForm
          formContext={formContext}
          formName={`${currentStep}.escalations`}
          maxLimit={10}
        />
      ) : null}
    </>
  );
};

PartnerOnBoardingSteps.propTypes = {
  currentStepIndex: PropTypes.string,
  formContext: PropTypes.shape({
    form: PropTypes.shape({
      change: PropTypes.func,
    }),
    values: PropTypes.shape({
      deliveryType: PropTypes.string,
      fileFormat: PropTypes.string,
    }),
  }).isRequired,
  onStepSelect: PropTypes.func,
  partnerId: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string }))
    .isRequired,
};

PartnerOnBoardingSteps.defaultProps = {
  currentStepIndex: 0,
  onStepSelect: () => null,
  partnerId: null,
};

export default PartnerOnBoardingSteps;
