import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import Card from '@shoreag/base/Card';
import Box from '@shoreag/base/Box';
import get from 'lodash/get';
import RemoveButton from '../../RemoveButton';
import AddButton from '../../AddButton';
import FlagHeading from '../../FlagHeading';
import GroupAssociationWithValuemapper from './GroupAssociationWithValuemapper';
import GroupAssociationDefaultFields from './DefaultForm';
import config from '../../../config.json';

const GroupAssociationForm = ({
  children,
  formContext,
  formName,
  maxLimit,
  allowDefaultOne,
  ...sx
}) => {
  if (allowDefaultOne && !get(formContext.values, formName)) {
    formContext.form.change(formName, [{}]);
  }
  return (
    <Box sx={sx}>
      <FieldArray name={formName}>
        {({ fields }) => {
          const totalFields = fields.length;
          return (
            <>
              <Box as="h2" sx={{ mb: 4, mt: 6 }}>
                Groups
              </Box>
              {totalFields > 0 ? (
                <>
                  {fields.map((name, index) => (
                    <Card
                      key={`${name}-${index}`}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 5,
                        // pb: 0,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          mx: -4,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            mb: 5,
                            px: 4,
                            width: '100%',
                          }}
                        >
                          <FlagHeading color="accent" sx={{ mt: 0 }}>
                            Group
                            <Box
                              bg="accent"
                              borderRadius={0}
                              color="white"
                              ml={3}
                              px={2}
                              py="2px"
                            >
                              {index + 1}
                            </Box>
                          </FlagHeading>
                          {!(allowDefaultOne && totalFields === 1) ? (
                            <RemoveButton onClick={() => fields.remove(index)}>
                              Remove
                            </RemoveButton>
                          ) : null}
                        </Box>
                        {config?.modules?.groupCondition ? (
                          <GroupAssociationWithValuemapper
                            formContext={formContext}
                            formName={`${name}.inputs`}
                          />
                        ) : (
                          <GroupAssociationDefaultFields formName={name} />
                        )}
                      </Box>
                    </Card>
                  ))}
                  {(!maxLimit || fields.length < maxLimit) && (
                    <AddButton
                      data-cy="addGroup"
                      label="Add Group"
                      onClick={() => fields.push({})}
                      wrapperSx={{ mt: 0 }}
                    />
                  )}
                </>
              ) : (
                <Card sx={{ display: 'flex', justifyContent: 'center' }}>
                  <AddButton
                    data-cy="addGroup"
                    label="Add Group"
                    onClick={() => fields.push({})}
                  />
                </Card>
              )}
            </>
          );
        }}
      </FieldArray>
    </Box>
  );
};

GroupAssociationForm.propTypes = {
  allowDefaultOne: PropTypes.bool,
  children: PropTypes.node,
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }),
  formName: PropTypes.string.isRequired,
  maxLimit: PropTypes.number,
};

GroupAssociationForm.defaultProps = {
  allowDefaultOne: false,
  children: null,
  formContext: {},
  maxLimit: null,
};

export default GroupAssociationForm;
