import Dropdown from '@shoreag/base/Dropdown';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';
import { Field } from 'react-final-form';
import { required } from '@shoreag/validations';
import allUserQuery from '../../graphql/queries/all-user.gql';
import MultiSelectDropdown from '../MultiSelectDropdown';

const AllUsersDropdown = ({
  isRequired,
  name,
  isMulti,
  expectedValue,
  ...rest
}) => {
  const [externalAsyncOptions, setExternalAsyncOptions] = useState([]);
  return (
    <ApolloConsumer>
      {(client) => (
        <Field
          component={isMulti ? MultiSelectDropdown : Dropdown}
          data-cy={name}
          externalAsyncOptions={externalAsyncOptions}
          label={`All Users ${isRequired ? '*' : ''}`}
          loadOptions={async (query) => {
            const {
              data: { allUser },
            } = await client.query({
              query: allUserQuery,
              variables: {
                searchBody: query,
              },
            });
            return allUser.map((user) => {
              const { email, givenName, familyName } = user;
              const value = user[expectedValue || 'email'];
              return {
                label: `${givenName} ${familyName}`,
                subtext: email,
                value,
              };
            });
          }}
          name={name}
          setExternalAsyncOptions={setExternalAsyncOptions}
          validate={isRequired ? required : null}
          {...rest}
        />
      )}
    </ApolloConsumer>
  );
};

AllUsersDropdown.propTypes = {
  expectedValue: PropTypes.string,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

AllUsersDropdown.defaultProps = {
  expectedValue: '',
  isMulti: false,
  isRequired: true,
};

export default AllUsersDropdown;
